import React, { useEffect, useState } from 'react'
import {
  AlertColor,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/material/styles'
import { updateProtocol } from 'utils/apiHelpers'
import { LogoFileZone } from '../LogoFileZone'

const amp = {
  name: 'Ampleforth',
  link: 'https://www.ampleforth.org/',
  logo: 'logos/ampleforth_logo.png',
  factor: '0.025',
  symbol: 'AMPL',
  desc: 'Open source wrapped tokens integrator',
  type: 1,
  chains: 'ethersToSerializedBigNumber',
  status: 'inactive',
}

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

interface ModalProps {
  item: any
  chainData: any[]
  open: boolean
  handleClose: () => void
  handleOpenSnackBar: (type: AlertColor, msg: string) => void
}

export const InputItem: React.FC<{
  label: string
  placeholder?: string
  value?: string | number
  handleChange?: (e: any) => void
}> = ({ label, placeholder, value, handleChange }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
      <Typography gutterBottom sx={{ minWidth: 60 }}>{`${label}:`}</Typography>
      <TextField
        fullWidth
        size="small"
        placeholder={placeholder}
        defaultValue={value}
        variant="outlined"
        sx={{ marginLeft: 5, minWidth: 250 }}
        onChange={handleChange}
      />
    </Box>
  )
}

export const NumberInputItem: React.FC<{
  label: string
  placeholder?: string
  value?: string | number
  handleChange?: (e: any) => void
}> = ({ label, placeholder, value, handleChange }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
      <Typography gutterBottom sx={{ minWidth: 60 }}>{`${label}:`}</Typography>
      <TextField
        fullWidth
        size="small"
        type="number"
        placeholder={placeholder}
        defaultValue={value}
        variant="outlined"
        sx={{ marginLeft: 5, minWidth: 250 }}
        onChange={handleChange}
      />
    </Box>
  )
}

export const TypeSelectBox: React.FC<{ value: string; handleSelectTypes?: (e: any, v: any) => void }> = ({
  value,
  handleSelectTypes,
}) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
      <Typography gutterBottom sx={{ minWidth: 60 }}>
        Type
      </Typography>
      <Autocomplete
        fullWidth
        size="small"
        disablePortal
        disableCloseOnSelect
        id="combo-box-demo"
        options={['0', '1', '2', '3']}
        defaultValue={value}
        sx={{ marginLeft: 5, minWidth: 250 }}
        renderInput={(params) => <TextField {...params} label="Product Type" />}
        onChange={handleSelectTypes}
      />
    </Box>
  )
}

export const ChainSelectBox: React.FC<{
  value: string[]
  chainData: any[]
  handleSelectChains?: (e: any, v: any) => void
}> = ({ value, chainData, handleSelectChains }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
      <Typography gutterBottom sx={{ minWidth: 60 }}>
        Chains
      </Typography>
      <Autocomplete
        multiple
        size="small"
        limitTags={2}
        disableCloseOnSelect
        id="multiple-limit-tags"
        options={chainData}
        getOptionLabel={(option: any) => option?.chain_name}
        value={value}
        renderInput={(params) => <TextField {...params} label="Supported chains" placeholder="chains" />}
        fullWidth
        sx={{ marginLeft: 5, minWidth: 250 }}
        onChange={handleSelectChains}
      />
    </Box>
  )
}

export const StatusSelectBox: React.FC<{ handleSelectStatus?: (e: any, v: any) => void; value: number }> = ({
  handleSelectStatus,
  value,
}) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
      <Typography gutterBottom sx={{ minWidth: 70 }}>
        Status :
      </Typography>
      <Autocomplete
        fullWidth
        disablePortal
        value={value === 1 ? 'Active' : 'Inactive'}
        // defaultValue={value === 1 ? 'Active' : 'Inactive'}
        size="small"
        id="select-box-demo"
        options={['Inactive', 'Active']}
        sx={{ marginLeft: 5, minWidth: 250 }}
        renderInput={(params) => <TextField {...params} label="Protocol Status" />}
        onChange={handleSelectStatus}
      />
    </Box>
  )
}

const EditProtocolModal: React.FC<ModalProps> = ({ item, open, chainData, handleClose, handleOpenSnackBar }) => {
  const [pId, setPId] = useState('')
  const [pName, setPName] = useState('')
  const [link, setLink] = useState('')
  const [logo, setLogo] = useState('')
  const [factor, setPFactor] = useState(0)
  const [chains, setChains] = useState<any[]>()
  const [protocolType, setProtocolType] = useState(2)
  const [protocolStatus, setProtocolStatus] = useState<number>(Number(item.status))
  const [symbol, setSymbol] = useState<string>('')
  const [description, setDescription] = useState<string>('')

  useEffect(() => {
    setPId(item.protocol_id)
    setPName(item.product_name)
    setLink(item.product_link)
    setLogo(item.product_logo)
    setPFactor(Number(item.premium_factor))
    // setChains(item.chains)
    setProtocolType(item.product_type)
    setProtocolStatus(Number(item.status))
    setSymbol(item.product_symbol)
    setDescription(item.product_description)
    if (chainData && item) {
      const temp = item?.chains.map((_id) => {
        return chainData.find((entry: any) => entry.chain_id === +_id)
      })
      setChains(temp.length > 0 ? temp : [])
    }
  }, [item])

  const handleSelectChains = (e: any, v: string[]) => {
    setChains(v)
  }

  const handleSelectTypes = (e: any, v: string) => {
    setProtocolType(Number(v))
  }

  const handleSelectStatus = (e: any, v: string) => {
    setProtocolStatus(v === 'Active' ? 1 : 0)
  }

  const handleAddProtocol = () => {
    const body = {
      _id: item._id,
      protocol_id: pId === '' ? item.protocol_id : pId,
      product_name: pName === '' ? item.product_name : pName,
      premium_factor: factor === 0 ? Number(item.premium_factor) : factor,
      product_type: protocolType,
      product_logo: logo === '' ? item.logo : logo,
      product_link: link === '' ? item.product_link : link,
      product_symbol: symbol === '' ? item.product_symbol : symbol,
      product_description: description === '' ? item.product_description : description,
      status: protocolStatus,
      DAO_approval_status: 3,
      chains: chains.map((_i: any) => _i.chain_id),
    }

    updateProtocol(body)
      .then((res: any) => {
        if (res.success) {
          handleOpenSnackBar('success', res.message)
          handleClose()
        } else {
          handleOpenSnackBar('error', res.message)
          handleClose()
        }
        setPId('')
        setPName('')
        setLink('')
        setLogo('')
        setPFactor(0)
        setChains['']
        setProtocolType(2)
      })
      .catch((err: any) => {
        handleOpenSnackBar('error', err.message)
        handleClose()
      })
  }

  // const chNames = item.chains?.map((id: number) => chainNames[`${id}`])
  return (
    <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle
        sx={{ m: 0, p: 2, textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <div />
        <Typography variant="h5">Update a protocol</Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <InputItem
          label="ID"
          placeholder="Protocol id"
          value={item.protocol_id}
          handleChange={(e) => setPId(e.target.value)}
        />
        <InputItem
          label="Name"
          placeholder="Protocol name"
          value={item.product_name}
          handleChange={(e) => setPName(e.target.value)}
        />
        <InputItem
          label="Link"
          placeholder="Protocol link"
          value={item.product_link}
          handleChange={(e) => setLink(e.target.value)}
        />
        {/*<InputItem*/}
        {/*  label="Logo"*/}
        {/*  placeholder="Protocol logo link"*/}
        {/*  value={logo || item.product_logo}*/}
        {/*  handleChange={(e) => setLogo(e.target.value)}*/}
        {/*/>*/}
        <NumberInputItem
          label="Factor"
          value={`${Number(item.premium_factor)}`}
          placeholder="Premium factor"
          handleChange={(e) => setPFactor(Number(e.target.value))}
        />
        <InputItem
          label="Symbol"
          placeholder="Protocol symbol"
          value={item.product_symbol}
          handleChange={(e) => setSymbol(e.target.value)}
        />
        <InputItem
          label="Description"
          placeholder="Protocol description"
          value={item.product_description}
          handleChange={(e) => setDescription(e.target.value)}
        />
        <TypeSelectBox value={`${item.product_type}`} handleSelectTypes={handleSelectTypes} />
        <ChainSelectBox value={chains} chainData={chainData} handleSelectChains={handleSelectChains} />
        <StatusSelectBox handleSelectStatus={handleSelectStatus} value={protocolStatus} />

        <Typography gutterBottom sx={{ minWidth: 60 }}>
          Logo
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <LogoFileZone
            currentLogo={item.product_logo}
            onImageChange={(url) => {
              setLogo(url)
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Box sx={{ py: 2 }}>
          <Button
            autoFocus
            color="primary"
            variant="contained"
            onClick={handleAddProtocol}
            disabled={pName === '' || link === '' || logo === '' || factor === 0}
          >
            Update Protocol
          </Button>
        </Box>
      </DialogActions>
    </BootstrapDialog>
  )
}

export default EditProtocolModal
