import React, { useState } from 'react'
import { Card, Box, AlertColor } from '@mui/material'
import ProtocolHeader from './ProtocolHeader'
import { useGetProtocolList } from 'hooks/useGetProtocolList'
import ProtocolsListResults from 'components/ProtocolListResults'
import AddProtocolModal from 'components/modals/AddProtocolModal'
import EditProtocolModal from 'components/modals/EditProtocolModal'
import { deleteProtocol } from 'utils/apiHelpers'
import CustomizedSnackbars from 'components/SnackBar'
import BuyPolicyModal from 'components/modals/BuyPolicyModal'
import ConfirmDlg from 'components/modals/ConfirmModal'
import { useGetChainListAll } from 'hooks/useGetChainData'

const ProtocolBook = () => {
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(0)
  const [searchText, setSearchText] = useState('')
  const [editItem, setEditItem] = useState({})
  const [open, setOpenAddProtocol] = useState(false)
  const [editOpen, setOpenEditProtocol] = useState(false)
  const [serverity, setServerity] = useState<AlertColor>('success')
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [message, setAlertMessage] = useState('')
  const [updated, setUpdated] = useState(false)
  const [status, setStatus] = useState([1])

  const [bookOpen, setOpenBookrotocol] = useState(false)
  const [bookItem, setBookItem] = useState({})

  const [confirmDlgOpen, setConfirmDlgOpen] = useState(false)
  const [deleteId, setDeleteId] = useState('')

  const data = useGetProtocolList(page, limit, status, searchText, updated)
  const chainData = useGetChainListAll()

  const { protocols, totalItems } = data

  const handleRemove = (id: string) => {
    setDeleteId(id)
    setConfirmDlgOpen(true)
  }

  const handleEdit = (item: any) => {
    setEditItem(item)
    setOpenEditProtocol(true)
  }

  const handleBuyPolicy = (item: any) => {
    setBookItem(item)
    setOpenBookrotocol(true)
  }

  const handleSnackBarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackBarOpen(false)
  }

  const handleOpenSnackBar = (type: AlertColor, msg: string) => {
    setServerity(type)
    setAlertMessage(msg)
    setSnackBarOpen(true)
    setUpdated(!updated)
  }

  const handleDeleteConfirm = () => {
    if (deleteId === '') {
      return
    }
    deleteProtocol({ _id: deleteId })
      .then((res: any) => {
        if (res.success) {
          setServerity('success')
          setAlertMessage(res.message)
          setSnackBarOpen(true)
          setUpdated(!updated)
          setConfirmDlgOpen(false)
          setDeleteId('')
        }
      })
      .catch((err: any) => {
        setServerity('error')
        setAlertMessage(err.message)
        setSnackBarOpen(true)
      })
  }

  return (
    <Card sx={{ width: '100%' }}>
      <ProtocolHeader handleAddNew={() => setOpenAddProtocol(true)} handleSearchText={(text) => setSearchText(text)} />
      <Box sx={{ mt: 3 }}>
        <ProtocolsListResults
          totalItems={totalItems}
          page={page}
          limit={limit}
          handleRemove={handleRemove}
          handleEdit={handleEdit}
          handleBuyPolicy={handleBuyPolicy}
          protocols={protocols ?? []}
          handleLimit={(value: number) => setLimit(value)}
          handlePage={(value: number) => setPage(value)}
          handleChangeStatus={(value: number[]) => setStatus(value)}
        />
      </Box>

      {open && (
        <AddProtocolModal
          open={open}
          chainData={chainData ? chainData.chains : []}
          handleClose={() => setOpenAddProtocol(false)}
          handleOpenSnackBar={handleOpenSnackBar}
        />
      )}

      <EditProtocolModal
        open={editOpen}
        chainData={chainData ? chainData.chains : []}
        item={editItem}
        handleClose={() => setOpenEditProtocol(false)}
        handleOpenSnackBar={handleOpenSnackBar}
      />
      <BuyPolicyModal
        open={bookOpen}
        item={bookItem}
        handleClose={() => setOpenBookrotocol(false)}
        handleOpenSnackBar={handleOpenSnackBar}
      />
      <ConfirmDlg
        open={confirmDlgOpen}
        handleClose={() => setConfirmDlgOpen(false)}
        handleConfirm={handleDeleteConfirm}
      />
      <CustomizedSnackbars
        serverity={serverity}
        snackBarOpen={snackBarOpen}
        message={message}
        handleSnackBarClose={handleSnackBarClose}
      />
    </Card>
  )
}

export default ProtocolBook
