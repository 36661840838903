import poolsConfig, { protocols } from 'config/constants/pools'
import BigNumber from 'bignumber.js'
import { getBep20Contract, getPoolContract, getLpContract } from 'utils/contractHelpers'
import { getAddress } from 'utils/addressHelpers'
import { getRiskPoolsTotalSupply, getPremiumBalance } from 'utils/graphHelpers'
import { getDecimalAmount, getBalanceAmount } from 'utils/formatBalance'
import { simpleRpcProvider } from 'utils/providers'
import { Address, PoolConfig } from 'config/constants/types'
import { lpTokens } from 'config/constants/tokens'

const usdcDecimals = 6

const emptyAddress: Address = {
  1: '',
  // 4: '',
  5: '',
  56: '',
  97: '',
  2221: '',
}

export const fetchPoolsTotalStaking = async () => {
  const ethPools = poolsConfig.filter((_) => _.ethPool && _.poolId !== 0)
  const amount = await simpleRpcProvider.getBalance(getAddress(poolsConfig[0] && poolsConfig[0].riskPoolAddress ? poolsConfig[0].riskPoolAddress : emptyAddress))

  const _calls = ethPools.map(async (_pool: PoolConfig) => {
    const ristPoolAddress = getAddress(_pool && _pool.riskPoolAddress? _pool.riskPoolAddress : emptyAddress)
    const contract = await getBep20Contract(getAddress(_pool.stakingToken.address ? _pool.stakingToken.address : emptyAddress));
    return contract.balanceOf(ristPoolAddress)
  })

  const ttStakedForEth = await Promise.all(_calls).then((value) => {
    return value
  })

  const ttStakedForEthInJson = ttStakedForEth.map((_) => _.toJSON())

  const oldPools = await getRiskPoolsTotalSupply()
  const zeus = getDecimalAmount(new BigNumber(oldPools[2].totalSupply), usdcDecimals)
  const artena = getDecimalAmount(new BigNumber(oldPools[0].totalSupply), usdcDecimals)
  const artemis = getDecimalAmount(new BigNumber(oldPools[1].totalSupply), usdcDecimals)

  const ttStaked = [
    amount.toJSON(),
    ...ttStakedForEthInJson,
    {
      type: "BigNumber",
      hex: `0x${zeus.toString(16)}`,
    },
    {
      type: "BigNumber",
      hex: `0x${artena.toString(16)}`,
    },
    {
      type: "BigNumber",
      hex: `0x${artemis.toString(16)}`,
    },
  ]

  return poolsConfig.map((p, index) => ({
    poolId: p.poolId,
    totalStaked: ttStaked[index],
  }))
}

export const fetchPoolsMaxSize = async () => {
  const max = [
    {
      type: "BigNumber",
      hex: "0x00",
    },
    {
      type: "BigNumber",
      hex: "0x00",
    },
    {
      type: "BigNumber",
      hex: "0x00",
    },
    {
      type: "BigNumber",
      hex: "0x07695a92c20d6fe00000",
    },
    {
      type: "BigNumber",
      hex: "0x22f0aafd00887d200000",
    },
    {
      type: "BigNumber",
      hex: "0x3f870857a3e0e3800000",
    },
  ]

  return poolsConfig.map((p, index) => ({
    poolId: p.poolId,
    maxSize: max[index],
  }))
}

export const fetchPremiumPoolBalanceByProtocal = async () => {
  const res = await getPremiumBalance();
  
  const res1 = getDecimalAmount(new BigNumber(res.filter((_: any) => _.id === '0')[0]?.premiumBalance)).toString(16)

  const res2 = getDecimalAmount(new BigNumber(res.filter((_: any) => _.id === '1')[0]?.premiumBalance)).toString(16)
  const res3 = getDecimalAmount(new BigNumber(res.filter((_: any) => _.id === '2')[0]?.premiumBalance)).toString(16)

  const res4 = getDecimalAmount(new BigNumber(res.filter((_: any) => _.id === '3')[0]?.premiumBalance)).toString(16)
  const res5 = getDecimalAmount(new BigNumber(res.filter((_: any) => _.id === '4')[0]?.premiumBalance)).toString(16)

  const ttPremiumBalance = [
    {
      type: "BigNumber",
      hex: `0x${res1}` // "0x043c33c1937564800000",
    },
    {
      type: "BigNumber",
      hex: res2 === 'NaN' ? "0x00" : `0x${res2}`,
    },
    {
      type: "BigNumber",
      hex: res3 === 'NaN' ? "0x00" : `0x${res3}` // "0x00",
    },
    {
      type: "BigNumber",
      hex: `0x${res4}` // "0x054b40b1f852bda00000",
    },
    {
      type: "BigNumber",
      hex: `0x${res5}` // "0x02a5a058fc295ed00000",
    },
  ]
  
  return protocols.map((p, index) => ({
    protocolId: p.protocolId,
    totalStaked: ttPremiumBalance[index],
  }))
}

export const fetchPoolInfos = async () => {
  const ethPools = poolsConfig.filter((_) => _.ethPool)
  const _calls = ethPools.map(async (_pool) => {
    const contract = await getPoolContract(_pool.contractAddress);
    return contract.poolInfo()
  })

  const values = await Promise.all(_calls).then((value) => {
    return value
  })

  const perBlockInJSON = values.map((_) => {
    return _.unoMultiplierPerBlock ? _.unoMultiplierPerBlock.toJSON() : _.toJSON()
  })

  const perBlocks = [
    ...perBlockInJSON,
    {
      type: "BigNumber",
      hex: `0x00`,
    },
    {
      type: "BigNumber",
      hex: `0x00`,
    },
    {
      type: "BigNumber",
      hex: `0x00`,
    },
  ]

  return poolsConfig.map((p, index) => ({
    poolId: p.poolId,
    rewardPerBlock: perBlocks[index],
  }))
}

export const fetchLpAmount = async () => {
  const returnRes = {}
  const _calls = lpTokens.map(async (element) => {
    returnRes[`${element.symbol}`] = 0;
    const contract = await getLpContract(element.address)
    return contract.lpPriceUno()
  })
  
  const prices = await Promise.all(_calls).then((res) => {
    return res;
  });
  for (let i = 0; i < lpTokens.length; i++) {
    returnRes[`${lpTokens[i].symbol}`] = getBalanceAmount(new BigNumber(prices[i].toString()), lpTokens[i].decimals).toNumber()
  }
  return returnRes
}
