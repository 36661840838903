import React from 'react'
import { Box, Button, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material'
import PerfectScrollbar from 'react-perfect-scrollbar'

const ChainsList: React.FC<{
  chains: any[]
  page?: number
  limit?: number
  totalCounts?: number
  setPage: (page: number) => void
  setLimit: (limit: number) => void
  handleDeleteItem: (id: string) => void
  handleEditChain: (item: any) => void
}> = ({ chains, page, limit, totalCounts, setPage, setLimit, handleDeleteItem, handleEditChain }) => {
  
  const handleLimitChange = (event: any) => {
    setLimit(event.target.value)
  }

  const handlePageChange = (event: any, newPage: number) => {
    setPage(newPage)
  }

  return (
    <Box>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 750, overflowX: 'auto' }}>
          <Table sx={{ overflowX: 'auto' }}>
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Chain Logo</TableCell>
                <TableCell>Chain Id</TableCell>
                <TableCell>Edit</TableCell>
                <TableCell>Remove</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {chains.slice(0, limit).map((item: any, index: number) => (
                <TableRow hover key={item._id}>
                  <TableCell>{page * limit + index + 1}</TableCell>
                  <TableCell>{item.chain_name}</TableCell>
                  <TableCell>{item.chain_logo}</TableCell>
                  <TableCell>{item.chain_id}</TableCell>
                  <TableCell>
                    <Box>
                      <Button
                        color="success"
                        disabled={false}
                        size="small"
                        variant="contained"
                        onClick={() => handleEditChain(item)}
                      >
                        Edit
                      </Button>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box>
                      <Button
                        color="error"
                        disabled={false}
                        size="small"
                        variant="contained"
                        onClick={() => handleDeleteItem(item._id)}
                      >
                        Delete
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={totalCounts}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Box>
  )
}

export default ChainsList
