import { useCallback } from 'react';
import { ethers } from 'ethers';
import { getTokenAddress, getSalesPolicyAddress } from '../utils/addressHelpers';
import { useTokenContract } from './useContract';

const useTokenApprove = (address = null, tokenSymbol = 'usdc') => {
  const tokenAddress = getTokenAddress(tokenSymbol);
  const tokenContract = useTokenContract(tokenAddress);
  const approveAddr = address ?? getSalesPolicyAddress();

  const handleApprove = useCallback(async () => {
    try {
      const tx = await tokenContract.approve(approveAddr, ethers.constants.MaxUint256);
      const receipt = await tx.wait();
      return receipt.status;
    } catch (e) {
      return false;
    }
  }, [approveAddr, tokenContract]);

  return { onApprove: handleApprove };
};

export default useTokenApprove;
