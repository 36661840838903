import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import Paper from '@mui/material/Paper'
import { useCoverGraph } from 'hooks/useCoverGraph'
import { Button, TableSortLabel } from '@mui/material'
import { useProtocols } from 'hooks/useProtocols'

function calculateDuration(duration: number) {
  const days = Math.floor(duration / 86400)

  if (days > 0) {
    return `${days} days`
  }

  const hours = Math.floor(duration / 3600)

  if (hours > 0) {
    return `${hours} hours`
  }

  const minutes = Math.floor(duration / 60)

  if (minutes > 0) {
    return `${minutes} minutes`
  }
}

export default function Page() {
  const { data } = useProtocols()
  const { query, nextPage, prevPage, hasNextPage, hasPrevPage, changeBlockNumberDirection, blockNumberDirection } =
    useCoverGraph()

  const rows = (query?.data?.policyPurchases ?? [])?.map((policy) => {
    return {
      id: policy.id,
      buyer: policy.buyer,
      amount: policy.coverageAmount,
      premiumPaid: policy.premiumPaid,
      coverageDuration: calculateDuration(+policy.coverageDuration),
      tx: policy.transaction,
      protocolAddress: policy.protocolAddress,
      timestamp: policy.timestamp,
    }
  })

  return (
    <div style={{ padding: 16 }}>
      <h2 style={{ textAlign: 'left' }}>Policy Purchases</h2>
      <TableContainer component={Paper} sx={{ maxHeight: '500px' }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Protocol</TableCell>
              <TableCell>
                <TableSortLabel active={true} direction={blockNumberDirection} onClick={changeBlockNumberDirection}>
                  Date
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell align="right">Premium Paid</TableCell>
              <TableCell align="right">Coverage Duration</TableCell>
              <TableCell align="right">Transaction</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>
                  {data?.[row.protocolAddress.toLowerCase()]?.product_name ? (
                    data?.[row.protocolAddress.toLowerCase()]?.product_name
                  ) : (
                    <Tooltip title={`Could not find protocol ${row.protocolAddress}`} arrow>
                      <span>Unknown</span>
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell>{new Date(+row.timestamp * 1000).toLocaleString()}</TableCell>
                <TableCell align="right">{new Intl.NumberFormat('en-US').format(row.amount)}</TableCell>
                <TableCell align="right">{row.premiumPaid}</TableCell>
                <TableCell align="right">{row.coverageDuration}</TableCell>
                <TableCell align="right">
                  <a
                    href={`${process.env.REACT_APP_MODE === 'testnet' ? 'https://sepolia.etherscan.io' : 'https://etherscan.io'}/tx/${row.tx}`}
                    target="_blank"
                  >
                    View on Etherscan
                  </a>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div style={{ display: 'flex', gap: 8, marginTop: 16, justifyContent: 'flex-end' }}>
        <Button variant="contained" onClick={prevPage} disabled={!hasPrevPage}>
          Prev
        </Button>
        <Button variant="contained" onClick={nextPage} disabled={!hasNextPage}>
          Next
        </Button>
      </div>
    </div>
  )
}
