import { useState, useEffect } from 'react'
import { fetchTokenList, fetchTokenListAll } from 'utils/apiHelpers'

export const useGetTokenListWithPagination = (page: number, limit: number, updated: boolean) => {
  const [data, setData] = useState<any>([])
  useEffect(() => {
    const fetch = async () => {
      const res: any = await fetchTokenList(page, limit)

      if (res?.success) {
        setData(res?.data)
      }
    }
    fetch()
  }, [page, limit, updated])

  return data
}
export const useGetTokenListAll = () => {
  const [data, setData] = useState<any>([])
  useEffect(() => {
    const fetch = async () => {
      const res: any = await fetchTokenListAll()
      if (res?.success) {
        setData(res?.data)
      }
    }
    fetch()
  }, [])

  return data
}
