import { useCallback } from 'react';
import { ethers } from 'ethers';
import { useWeb3React } from '@web3-react/core';
import buySalesPolicy from '../utils/calls/salesPolicy';
import { useInitBiconomy } from './useBiconomy';

const useBuyPolicy = () => {
  const { account, chainId } = useWeb3React();
  const { salesPolicyContract } = useInitBiconomy();

  const handleBuyPolicy = useCallback(async (param) => {
    try {
      const walletProvider = new ethers.providers.Web3Provider(window.ethereum);

      if (param.signature) {
        const tx = await buySalesPolicy(
          salesPolicyContract,
          param,
          walletProvider,
          account,
          chainId
        )
        const receipt = await tx.wait();
        return receipt.status;
      }
    } catch (e) {
      return false;
    }
  }, [salesPolicyContract, account, chainId]);

  return { onBuyPolicy: handleBuyPolicy };
};

export default useBuyPolicy;
