import React, { useEffect, useState } from 'react'
import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  AlertColor,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/material/styles'
import { addChain, addProtocol } from 'utils/apiHelpers'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

interface ModalProps {
  open: boolean
  chainData: number
  handleClose: () => void
  handleOpenSnackBar: (type: AlertColor, msg: string) => void
}

const InputItem: React.FC<{
  label: string
  placeholder?: string
  value?: string | number
  error?: boolean
  disabled?: boolean
  handleChange?: (e: any) => void
}> = ({ label, placeholder, value, error, disabled, handleChange }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
      <Typography gutterBottom sx={{ minWidth: 70 }}>{`${label} :`}</Typography>
      <TextField
        value={value}
        error={error}
        disabled={disabled}
        fullWidth
        size="small"
        placeholder={placeholder}
        variant="outlined"
        sx={{ marginLeft: 5, minWidth: 250 }}
        onChange={handleChange}
      />
    </Box>
  )
}

const AddChainModal: React.FC<ModalProps> = ({ open, chainData, handleClose, handleOpenSnackBar }) => {
  const [chainName, setChainName] = useState('')
  const [chainID, setChainID] = useState<number>(0)
  const [logo, setLogo] = useState('')

  useEffect(() => {
    if (chainData) {
      setChainID(chainData + 1)
    }
  }, [chainData])

  const handleAddChain = () => {
    if (!validation()) {
      return
    }
    const body = {
      chain_id: chainID,
      chain_name: chainName,
      chain_logo: logo,
    }
    addChain(body)
      .then((res: any) => {
        if (res.success) {
          handleOpenSnackBar('success', res.message)
          handleClose()
        } else {
          handleOpenSnackBar('error', res.message)
          handleClose()
        }
      })
      .catch((err: any) => {
        handleOpenSnackBar('error', err.message)
        handleClose()
      })
  }

  const validation = () => {
    if (chainName === '') {
      handleOpenSnackBar('warning', 'Chain name is required.')
      return false
    }
    if (logo === '') {
      handleOpenSnackBar('warning', 'Chain logo link is required.')
      return false
    }
    return true
  }

  return (
    <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle
        sx={{ m: 0, p: 2, textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <div />
        <Typography variant="h5">Add a new chain</Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <InputItem
          label="ChainID*"
          disabled
          error={chainID === 0}
          placeholder="Chain id"
          value={chainID}
          // handleChange={(e) => setPName(e.target.value)}
        />
        <InputItem
          label="Name*"
          placeholder="Chain name"
          error={chainName === ''}
          handleChange={(e) => setChainName(e.target.value)}
        />
        <InputItem
          label="Logo*"
          placeholder="Chain logo link"
          error={logo === ''}
          handleChange={(e) => setLogo(e.target.value)}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Box sx={{ py: 2 }}>
          <Button autoFocus color="primary" variant="contained" onClick={handleAddChain}>
            Add Chain
          </Button>
        </Box>
      </DialogActions>
    </BootstrapDialog>
  )
}

export default AddChainModal
