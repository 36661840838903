import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { AlertColor, Box, Card } from '@mui/material';
import PageHeader from './components/PageHeader';
import TokensList from './components/TokensList'
import { useGetTokenListWithPagination } from 'hooks/useGetTokenData';
import ConfirmDlg from 'components/modals/ConfirmModal';
import CustomizedSnackbars from 'components/SnackBar';
import { deleteToken } from '../../utils/apiHelpers';
import AddTokenDlg from './components/modals/AddTokenDlg';
import { useGetChainListAll } from 'hooks/useGetChainData';
import EditTokenDlg from './components/modals/EditTokenDlg';
import { useAuthState } from 'state/auth/hooks'

const Tokens = () => {
  const [openAddDlg, setOpenAddDlg] = useState<boolean>(false)
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(0)
  const [updated, setUpdated] = useState(false)

  const [deleteId, setDeleteId] = useState('')
  const [confirmDlgOpen, setConfirmDlgOpen] = useState(false)
  const [serverity, setServerity] = useState<AlertColor>('success')
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [message, setAlertMessage] = useState('')

  const [openEditDlg, setOpenEditDlg] = useState<boolean>(false)
  const [editItem, setEditItem] = useState({})

  const { chains } = useGetChainListAll(updated)

  const { totalItems, tokens } = useGetTokenListWithPagination(page, limit, updated)
  const { userName } = useAuthState()
  const navigate = useNavigate()
  
  useEffect(() => {
    if (userName === '') {
      navigate('/');
    }
  }, [])

  const handleOpenAddTokenDlg = () => {
    setOpenAddDlg(true)
  }

  const handleSetPage = (value: number) => {
    setPage(value)
  }
  
  const handleSetLimit = (value: number) => {
    setLimit(value)
  }
  
  const handleEditToken = (item: any) => {
    setEditItem(item)
    setOpenEditDlg(true)
  }
  
  const handleDeleteItem = (id: string) => {
    setDeleteId(id)
    setConfirmDlgOpen(true)
  }

  const handleOpenSnackBar = (type: AlertColor, msg: string) => {
    setServerity(type)
    setAlertMessage(msg)
    setSnackBarOpen(true)
    setUpdated(!updated)
  }
  const handleSnackBarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackBarOpen(false)
  }
  const handleDeleteConfirm = () => {
    if (deleteId === '') {
      return
    }
    deleteToken(deleteId)
      .then((res: any) => {
        if (res.success) {
          setServerity('success')
          setAlertMessage(res.message)
          setSnackBarOpen(true)
          setUpdated(!updated)
          setConfirmDlgOpen(false)
          setDeleteId('')
        }
      })
      .catch((err: any) => {
        setServerity('error')
        setAlertMessage(err.message)
        setSnackBarOpen(true)
      })
  }

  return (
    <>
      <Box
        component="main"
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          p: '20px',
        }}
      >
        <Card sx={{ marginTop: 10, overflowX: 'auto', minWidth: '280px', width: '100%', maxWidth: '800px' }}>
          <PageHeader handleOpenAddChainDlg = {handleOpenAddTokenDlg} />
          <TokensList
            tokens={tokens ?? []}
            page={page}
            limit={limit}
            totalCounts={totalItems ?? 0}
            setPage={handleSetPage}
            setLimit={handleSetLimit}
            handleDeleteItem={handleDeleteItem}
            handleEditToken={handleEditToken}
          />
        </Card>
      </Box>
      <AddTokenDlg
        open={openAddDlg}
        chainData={chains ?? []}
        handleClose={() => setOpenAddDlg(false)}
        handleOpenSnackBar={handleOpenSnackBar}
      />
      <EditTokenDlg
        open={openEditDlg}
        item={editItem}
        chainData={chains ?? []}
        handleClose={() => setOpenEditDlg(false)}
        handleOpenSnackBar={handleOpenSnackBar}
      />
      <ConfirmDlg
        open={confirmDlgOpen}
        handleClose={() => setConfirmDlgOpen(false)}
        handleConfirm={handleDeleteConfirm}
      />
      <CustomizedSnackbars
        serverity={serverity}
        snackBarOpen={snackBarOpen}
        message={message}
        handleSnackBarClose={handleSnackBarClose}
      />

    </>
  )
}

export default Tokens
