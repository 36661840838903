import React from 'react';
import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  styled,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

const ConfirmDlg: React.FC<ModalProps> = ({ open, handleClose, handleConfirm }) => {

  return (
    <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle sx={{ m: 0, p: 2, textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div />
        <Typography variant="h5">Confirmation</Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="h5">Do you want to be deleted?</Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Box sx={{ py: 2 }}>
          <Button autoFocus color="primary" variant="contained" onClick={handleConfirm} sx={{minWidth: 100}}>
            Confirm
          </Button>
        </Box>
        <Box sx={{ py: 2 }}>
          <Button autoFocus color="primary" variant="contained" onClick={handleClose} sx={{minWidth: 100}}>
            Cancel
          </Button>
        </Box>
      </DialogActions>
    </BootstrapDialog>
  )
}

export default ConfirmDlg;
