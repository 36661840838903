import { useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'

interface ProtocolListProps {
  page?: number
  limit?: number
  addresses?: any
  totalCounts?: number
  setPage: (page: number) => void
  setLimit: (limit: number) => void
  hanleEditBlacklist: (item: any) => void
  hanleDeleteBlacklist: (id: string, address: string, pId: string) => void
}

const AddressListResults: React.FC<ProtocolListProps> = ({
  addresses,
  page,
  limit,
  totalCounts,
  setPage,
  setLimit,
  hanleEditBlacklist,
  hanleDeleteBlacklist,
}) => {
  const handleLimitChange = (event: any) => {
    setLimit(event.target.value)
  }

  const handlePageChange = (event: any, newPage: number) => {
    setPage(newPage)
  }

  return (
    <Box>
      <Typography color="textPrimary" variant="h5" sx={{ py: 2, marginTop: 5 }}>
        {addresses?.length ? 'Below addresses are whitelisted' : 'No have whitelist address.'}
      </Typography>
      {addresses?.length > 0 && (
        <PerfectScrollbar>
          <Box sx={{ minWidth: 750, overflowX: 'auto' }}>
            <Table sx={{ overflowX: 'auto' }}>
              <TableHead>
                <TableRow>
                  <TableCell>ADDRESS</TableCell>
                  <TableCell>STATUS</TableCell>
                  <TableCell>EDIT</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {addresses.slice(0, limit).map((item: any) => {
                  if (item.contract_addr_list?.length === 0) {
                    return null
                  }
                  return (
                    <TableRow hover key={item._id}>
                      <TableCell>{item.contract_addr_list[0]?.address}</TableCell>
                      <TableCell>{item.contract_addr_list[0]?.status ? 'Active' : 'Inactive'}</TableCell>
                      <TableCell>
                        <Box>
                          <Button
                            color="info"
                            disabled={false}
                            size="small"
                            variant="contained"
                            onClick={() => hanleEditBlacklist(item)}
                          >
                            Edit
                          </Button>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box>
                          <Button
                            color="error"
                            disabled={false}
                            size="small"
                            variant="contained"
                            onClick={() => hanleDeleteBlacklist(item.chain_id, item.contract_addr_list[0]?.address, item.contract_addr_list[0]?.protocol_id)}
                          >
                            Delete
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      )}
      {addresses?.length > 0 && (
        <TablePagination
          component="div"
          count={totalCounts}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      )}
    </Box>
  )
}

export default AddressListResults
