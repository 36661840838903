import React from 'react'
import { Box, Button, Card, CardContent, SvgIcon, InputAdornment, TextField, Grid, Typography } from '@mui/material'
import { Search as SearchIcon } from 'assets/icons/search'
interface ProtocolHeaderProps {
  handleAddNew: () => void;
  handleSearchText: (value: string) => void;
}

const ProtocolHeader: React.FC<ProtocolHeaderProps> = ({ handleAddNew, handleSearchText }) => {
  
  return (
    <Grid container spacing={3} sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
      <Grid item lg={3} md={6} xs={12}>
        <Typography color="textPrimary" variant="h5" sx={{ py: 2 }}>
          All Protocols
        </Typography>
      </Grid>

      <Grid item lg={4} md={4} sm={6} xs={12} sx={{ justifyContent: 'flex-end' }}>
        <Box>
            <CardContent>
              <Box sx={{ maxWidth: 600 }}>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon color="action" fontSize="small">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                  placeholder="Search by name, link and premium factor"
                  variant="outlined"
                  onChange={(e: any) => handleSearchText(e.target.value)}
                />
              </Box>
            </CardContent>
        </Box>
      </Grid>

      <Grid item lg={3} md={3} xs={12} sm={6} sx={{ justifyContent: 'flex-end' }}>
        <Box sx={{ py: 2 }}>
          <Button color="primary" disabled={false} size="small" variant="contained" onClick={handleAddNew}>
            Add New
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}

export default ProtocolHeader
