import { ApolloClient, gql, InMemoryCache, useQuery } from '@apollo/client'
import { useState } from 'react'

export const coverClient = new ApolloClient({
  uri:
    process.env.REACT_APP_MODE === 'testnet'
      ? 'https://api.studio.thegraph.com/query/78945/uno-sales-sepolia-eth/version/latest'
      : 'https://api.studio.thegraph.com/query/81903/unore-cover-portal-subgraph/version/latest',
  cache: new InMemoryCache(),
})

const GET_PURCHASES = gql`
  query MyQuery($skip: Int = 0, $direction: String = "desc") {
    policyPurchases(orderBy: blockNumber, orderDirection: $direction, skip: $skip, first: 100) {
      id
      buyer
      transaction
      coverageAmount
      coverageDuration
      blockNumber
      policyId
      premiumCurrency
      premiumPaid
      protocolAddress
      timestamp
    }
  }
`

export function useCoverGraph() {
  const [page, setPage] = useState(0)
  const [blockNumberDirection, setBlockNumberDirection] = useState<'asc' | 'desc'>('desc')

  function nextPage() {
    setPage(page + 1)
  }

  function prevPage() {
    setPage(page - 1)
  }

  function changeBlockNumberDirection() {
    setBlockNumberDirection(blockNumberDirection === 'desc' ? 'asc' : 'desc')
  }

  const query = useQuery(GET_PURCHASES, {
    client: coverClient,
    variables: {
      skip: page * 100,
      direction: blockNumberDirection,
    },
  })

  return {
    query,
    nextPage,
    prevPage,
    blockNumberDirection,
    changeBlockNumberDirection,
    hasNextPage: query.data?.policyPurchases?.length === 100,
    hasPrevPage: page > 0,
  }
}
