const tokens = {
  eth: {
    symbol: 'ETH',
    projectLink: '',
  },
  weth: {
    symbol: 'ETH',
    address: {
      1: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
      4: '0xc778417E063141139Fce010982780140Aa0cD5Ab',
      5: '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6',
      56: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      97: '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
    },
    decimals: 18,
    projectLink: '',
  },
  usdc: {
    symbol: 'USDC',
    address: {
      1: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
      4: '0x68C34F54E63664019f59029b9dE344d6219C7758',
      5: '0xCDE065b33Aae456cc85Dcf4BcE99926c676AE79f',
      56: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      97: '0x2985F2F8Ffa19Aa74008A9a1331da69A57d231E1',
    },
    decimals: 6,
    projectLink: 'https://www.centre.io/usdc',
  },
  usdt: {
    symbol: 'USDT',
    address: {
      1: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
      4: '0x40c035016AD732b6cFce34c3F881040B6C6cf71E',
      5: '0xb1Ce55d27FaF8D4499b840A3EDf509E4df43f9E1',
      56: '0x68C34F54E63664019f59029b9dE344d6219C7758',
      97: '0xcD0A4a40f04d486b240910cDaaB0AA3D52DE3462',
    },
    decimals: 6,
    projectLink: 'https://tether.to/en/',
  },
  usdcbsc: {
    symbol: 'USDC',
    address: {
      1: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
      4: '0x68C34F54E63664019f59029b9dE344d6219C7758',
      5: '0xb1Ce55d27FaF8D4499b840A3EDf509E4df43f9E1',
      56: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      97: '0x188bf631F2272B61Dd61119fbD264aeC7b6C57D5',
    },
    decimals: 18,
    projectLink: 'https://www.centre.io/usdc',
  },
  uno: {
    symbol: 'UNO',
    address: {
      1: '0x474021845C4643113458ea4414bdb7fB74A01A77',
      4: '0x53fb43BaE4C13d6AFAD37fB37c3fC49f3Af433F5',
      5: '0x491e7B202Ca6eB8beFb5eC4C6a3D68ce2159dcF2',
      56: '0x474021845C4643113458ea4414bdb7fB74A01A77',
      97: '0x89C839ebc77FC4d13F82047EDa0843c1F0B12d51',
    },
    decimals: 18,
    projectLink: 'https://unore.io/',
  },
  horde: {
    symbol: 'HORDE',
    address: {
      1: '0x474021845C4643113458ea4414bdb7fB74A01A77',
      4: '0x53fb43BaE4C13d6AFAD37fB37c3fC49f3Af433F5',
      5: '0x491e7B202Ca6eB8beFb5eC4C6a3D68ce2159dcF2',
      56: '0x0069FF45ea9F7c28E0A2dEDb9BEf8144af8131BC',
      97: '0x199c66ab7273Fa711F5CCe0fbd92f3Ff4a1f2939',
    },
    decimals: 18,
    projectLink: 'https://horde.io/',
  },
  ethlp: {
    symbol: 'SSIP-ETH LP',
    address: {
      1: '0x29B4b8674D93b36Bf651d0b86A8e5bE3c378aCF4',
      5: '0x73EdfE2b622A9d7453DbaA2a10766FB98a71Af3A',
      56: '',
      97: '0xBC655a1aB7E9FF9a62b4187e45c48DA5037C264a',
    },
    decimals: 18,
    projectLink: '',
  },
  unolp: {
    symbol: 'SSIP-UNO LP',
    address: {
      1: '0xbd3E70819A8Add92B06d6d92A06DcdA9249DF2a3',
      4: '0x02161410F5594FbD0f73070eD16c4fDD2d84A956',
      56: '',
      97: '',
    },
    decimals: 18,
    projectLink: '',
  },
  ssrplp: {
    symbol: 'SSRP-UNO LP',
    address: {
      1: '0x1eECc8C8298ed9Bd46c147D44E2D7A7BfACE2034',
      5: '0x36Bc245184D2acfEfCA17c582CA356d81Ac7788f',
      56: '',
      97: '0xCeeDd150b9B09a865E21acA29DD5F329BaF9c49d',
    },
    decimals: 18,
    projectLink: '',
  },
}

export const usdPriceTokenList = [
  {
    symbol: 'ETH',
    id: 'weth',
  },
  {
    symbol: 'USDC',
    id: 'usd-coin',
  },
  {
    symbol: 'UNO',
    id: 'uno-re',
  },
  {
    symbol: 'USDT',
    id: 'tether',
  },
  {
    symbol: 'HORDE',
    id: 'horde-token',
  },
]

export const lpTokens = [
  {
    symbol: 'SSIP-ETH LP',
    originTokenSymbol: 'ETH',
    address: {
      1: '0x29B4b8674D93b36Bf651d0b86A8e5bE3c378aCF4',
      5: '0x73EdfE2b622A9d7453DbaA2a10766FB98a71Af3A',
      56: '0xBC655a1aB7E9FF9a62b4187e45c48DA5037C264a',
      97: '',
    },
    decimals: 18,
    projectLink: '',
  },
  {
    symbol: 'SSIP-UNO LP',
    originTokenSymbol: 'UNO',
    address: {
      1: '0xbd3E70819A8Add92B06d6d92A06DcdA9249DF2a3',
      5: '0x7b2AD0f0be8c7BC560399268dDD26cc8781747a1',
      56: '',
      97: '0x45f22138dfACfFf1e574c0B0f7fe8BBF04eF86F7',
    },
    decimals: 18,
    projectLink: '',
  },
  {
    symbol: 'SSIP-USDT LP',
    originTokenSymbol: 'USDT',
    address: {
      1: '0x920D510D5c70C01989b66f4e24687Dddb988DdAe',
      5: '0x3E19a8D7e3c36f0122EF6D0b8053e639318c37F6',
      56: '',
      97: '0x2c10C901a81096F78E25Ac68D2c0b849c879420D',
    },
    decimals: 18,
    projectLink: '',
  },
  {
    symbol: 'SSIP-USDC LP',
    originTokenSymbol: 'USDC',
    address: {
      1: '0xfdfAA453eF3709D2c26EcF43786a14Ab8bF27E36',
      5: '0xe336966836973A26530Cd41E94cfF87Db48B75b4',
      56: '',
      97: '0x03c626BFb9F1BB3C899109375DDAb0a81eAe3756',
    },
    decimals: 18,
    projectLink: '',
  },
  {
    symbol: 'SSRP-UNO LP',
    originTokenSymbol: 'UNO',
    address: {
      1: '0x1eECc8C8298ed9Bd46c147D44E2D7A7BfACE2034',
      5: '0x36Bc245184D2acfEfCA17c582CA356d81Ac7788f',
      56: '',
      97: '0xCeeDd150b9B09a865E21acA29DD5F329BaF9c49d',
    },
    decimals: 18,
    projectLink: '',
  },
]

export default tokens
