import React, { useState, useEffect } from 'react'
import {
  AlertColor,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  IconButton,
  OutlinedInput,
  InputAdornment,
  Grid,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { BootstrapDialog } from './AddProtocolModal'
import useGetPremiumPrices, { useGetUSDCAmtForOneDollar } from 'hooks/useGetPremiumPrices'
import useAuth from 'hooks/useAuth'
import { formatFixedNumber3, getBalanceAmount } from 'utils/formatBalance'
import { getTokenAddress } from 'utils/addressHelpers'
import { fetchFunctionSignatureInRinkeby } from 'utils/apiHelpers'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { ConnectorNames } from 'utils/web3React'
import useGetAllowanceOfToken from 'hooks/useGetAllowanceOfToken'
import useTokenApprove from 'hooks/useTokenApprove'
import useTokenBalance, { useGetNativeCoinBalance } from 'hooks/useTokenBalance'
import useBuyPolicy from 'hooks/useBuyPolicy'
import { getBigNumberWithDecimal } from '../../utils/calls/salesPolicy'
// import { getBlockTimestamp } from 'utils/getBlockTimestamp'

interface ModalProps {
  item: any
  open: boolean
  handleClose: () => void
  handleOpenSnackBar: (type: AlertColor, msg: string) => void
}

export const InputItem: React.FC<{
  label?: string
  placeholder?: string
  value?: string | number
  error?: boolean
  disabled?: boolean
  adornment?: string
  handleChange?: (e: any) => void
}> = ({ label, placeholder, value, disabled, error, adornment, handleChange }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1 }}>
      <Typography gutterBottom>{`${label}:`}</Typography>
      <OutlinedInput
        error={error}
        fullWidth
        size="small"
        type="number"
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        endAdornment={<InputAdornment position="end">{adornment}</InputAdornment>}
        sx={{ marginLeft: 5, width: 200, mozAppearance: 'none'}}
        onChange={handleChange}
      />
    </Box>
  )
}

const BuyPolicyModal: React.FC<ModalProps> = ({ item, open, handleClose, handleOpenSnackBar }) => {
  const [sumAssured, setSumAssued] = useState(0)
  const [duration, setDuration] = useState(30)
  const [discount, setDiscount] = useState(7)

  const { login } = useAuth()
  const { account } = useActiveWeb3React()

  const { usdcAllowance, handleAllowance } = useGetAllowanceOfToken()
  const { onApprove } = useTokenApprove()
  const { onBuyPolicy } = useBuyPolicy()

  const { balance } = useTokenBalance(getTokenAddress('usdc'))
  const ethBalance = useGetNativeCoinBalance()

  useEffect(() => {
    const get = async () => {
      await handleAllowance()
    }
    get()
  }, [handleAllowance])

  const pf = (Number(item.premium_factor) * (1 - Number(discount) / 100))

  const selectedProtocolData = [
    {
      coverType: item.product_type,
      premiumFactor: Number(pf),
      coverDuration: duration,
      coverAmount: sumAssured,
    },
  ]

  const { usdcAmtForOneDollar } = useGetUSDCAmtForOneDollar()
  const { premiumTotal, isSent } = useGetPremiumPrices(selectedProtocolData, sumAssured, duration, discount)


  const handleAddProtocol = async () => {
    const utcTime = new Date().getTime()
    const deadline = Math.floor(utcTime / 1000) // await getBlockTimestamp()

    if (!account) {
      await login(ConnectorNames.Injected)
      return
    }

    if (!usdcAllowance) {
      const approveStatus = await onApprove()
      if (approveStatus) {
        await handleAllowance()
      }
      return
    }

    const decimalBalance = getBalanceAmount(balance, 6)
    // const stringBalance = decimalBalance?.toString().split('.')[0]

    if (premiumTotal * usdcAmtForOneDollar > decimalBalance.toNumber()) {
      handleOpenSnackBar('warning', 'Insufficient USDC balance.')
      return
    }

    if (ethBalance?.toNumber() < 0.0005) {
      handleOpenSnackBar('warning', 'Insufficient ETH balance.')
      return
    }

    if (item.protocol_id === undefined) {
      handleOpenSnackBar('warning', 'Protocol ID is undefined.')
      return
    }

    const selectedData = {
      coverPrice: Number(premiumTotal * usdcAmtForOneDollar),
      protocols: [item.protocol_id],
      premiumFactor: [Number(pf)],
      coverDuration: [duration],
      coverAmount: [Number(sumAssured)],
      deadline,
      usdcAddress: getTokenAddress('usdc'),
      usdcAmtForOneDollar,
    }

    fetchFunctionSignatureInRinkeby(selectedData)
      .then((res: any) => {
        if (res.success) {
          const { signature } = res.data
          handleClickPay({
            ...selectedData,
            signature,
            assetAddresses: [item.coverType === 0 ? '0x0000000000000000000000000000000000000000' : item.protocol_id],
          })
        } else {
          handleOpenSnackBar('error', 'Failed to get signature.')
        }
      })
      .catch((err: any) => {
        handleOpenSnackBar('error', 'Failed to get signature.')
      })
  }

  const handleClickPay = async (selectedData: any) => {
    const buyRes = await onBuyPolicy(selectedData)

    if (buyRes) {
      handleOpenSnackBar('success', `Purchased the policy successfully!`)
      setSumAssued(0)
      setDuration(30)
      setDiscount(7)
      handleClose()
    } else {
      handleOpenSnackBar('warning', 'Failed to puchase transaction!')
    }
  }

  const handleClickDur = (dur: number) => {
    setDuration(dur)
  }

  const usdcAmt = formatFixedNumber3(premiumTotal * usdcAmtForOneDollar, 9)

  return (
    <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle
        sx={{ m: 0, p: 2, textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <div />
        <Typography variant="h5">Buy a Policy</Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <InputItem
          label="Coverage Amount (in USD)"
          placeholder="Sum assured"
          error={sumAssured === 0}
          value={sumAssured}
          adornment="USD"
          handleChange={(e) => {
            if (Number(e.target.value) < 0) {
              return
            }
            const val = e.target.value.replace(/,/g, '.')
            const realVal = val.replace(/^0+/, '');
            setSumAssued(realVal)
          }}
        />
        <InputItem
          label="Discount Factor (in %)"
          placeholder="Discount"
          error={discount === 0}
          value={discount}
          adornment="%"
          handleChange={(e) => {
            if (Number(e.target.value) < 0 || Number(e.target.value) > 100) {
              return
            }
            const val = e.target.value.replace(/,/g, '.')
            const realVal = val.replace(/^0+/, '');
            // const valArr = val.split('.')
            // const realValue = val.includes('.') && valArr !== undefined ? `${valArr[0]}.${valArr[1].substring(0, 3)}` : val
            setDiscount(Number(realVal))
          }}
        />
        <InputItem
          disabled
          label="Discounted Premium"
          placeholder="Discount"
          error={discount === 0}
          value={Number(pf)}
        />
        <InputItem
          disabled
          label="Standard Premium"
          placeholder="Discount"
          error={Number(item.premium_factor) === 0}
          value={item.premium_factor}
        />
        <InputItem
          label="Policy Duration (in days)"
          placeholder="Duration"
          error={duration === 0}
          value={duration}
          adornment="days"
          handleChange={(e) => {
            if (Number(e.target.value) < 0) {
              return
            }
            const val = e.target.value.replace(/,/g, '.')
            const realVal = val.replace(/^0+/, '');
            setDuration(realVal)
          }}
        />
        {/* <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, justifyContent: 'flex-end' }}> */}
        <Grid container spacing={3} sx={{ justifyContent: 'flex-end' }}>
          <Grid item lg={3} md={6}>
            <Typography
              sx={{
                border: duration === 30 ? '1px solid blue' : '1px solid grey',
                cursor: 'pointer',
                color: duration === 30 ? 'blue' : 'grey',
                minWidth: '100px',
                textAlign: 'center'
              }}
              onClick={() => handleClickDur(30)}
            >
              {' '}
              Monthly{' '}
            </Typography>
          </Grid>
          <Grid item lg={3} md={6}>
            <Typography
              sx={{
                border: duration === 90 ? '1px solid blue' : '1px solid grey',
                color: duration === 90 ? 'blue' : 'grey',
                cursor: 'pointer',
                minWidth: '100px',
                textAlign: 'center'
              }}
              onClick={() => handleClickDur(90)}
            >
              {' '}
              Quarterly{' '}
            </Typography>
          </Grid>
          <Grid item lg={3} md={6}>
            <Typography
              sx={{
                border: duration === 183 ? '1px solid blue' : '1px solid grey',
                color: duration === 183 ? 'blue' : 'grey',
                cursor: 'pointer',
                minWidth: '100px',
                textAlign: 'center'
              }}
              onClick={() => handleClickDur(183)}
            >
              Half-yearly
            </Typography>
          </Grid>
          <Grid item lg={3} md={6}>
            <Typography
              sx={{
                border: duration === 365 ? '1px solid blue' : '1px solid grey',
                color: duration === 365 ? 'blue' : 'grey',
                cursor: 'pointer',
                minWidth: '100px',
                textAlign: 'center'
              }}
              onClick={() => handleClickDur(365)}
            >
              {' '}
              Annually{' '}
            </Typography>
          </Grid>
        </Grid>
        {/* </Box> */}

        <Typography sx={{ my: 3 }}>{`Price in USDC : ${usdcAmt} USDC`}</Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Box sx={{ py: 2 }}>
          <Button
            autoFocus
            color="primary"
            variant="contained"
            onClick={handleAddProtocol}
            disabled={(premiumTotal === 0 || isSent) && usdcAllowance && account !== null}
          >
            {!account ? 'Connect Wallet' : !usdcAllowance ? 'Approve' : 'Buy Policy'}
          </Button>
        </Box>
      </DialogActions>
    </BootstrapDialog>
  )
}

export default BuyPolicyModal
