import { ethers } from 'ethers';

export const getSignatureParameters = (signature) => {
  if (!ethers.utils.isHexString(signature)) {
    throw new Error('Given value "'.concat(signature, '" is not a valid hex string.'));
  }
  const r = signature.slice(0, 66);
  const s = '0x'.concat(signature.slice(66, 130));
  const v = '0x'.concat(signature.slice(130, 132));
  let v1: number = ethers.BigNumber.from(v).toNumber();
  if (![27, 28].includes(v1)) v1 += 27;
  return { r, s, v: v1 };
};

const getMetaTransactionEIP712SignConfig = (
  chainId,
  userAddress,
  domainName,
  contractAddress,
  functionSignature,
  nonce,
  isVenly = false
) => {
  const domainType = [
    { name: 'name', type: 'string' },
    { name: 'version', type: 'string' },
    { name: 'verifyingContract', type: 'address' },
    { name: 'salt', type: 'bytes32' },
  ]

  const metaTransactionType = [
    { name: 'nonce', type: 'uint256' },
    { name: 'from', type: 'address' },
    { name: 'functionSignature', type: 'bytes' },
  ]

  const domainData = {
    name: domainName,
    version: '1',
    verifyingContract: contractAddress,
    salt: ethers.utils.hexZeroPad(ethers.BigNumber.from(chainId).toHexString(), 32),
  }

  const message = {
    nonce: nonce.toNumber(),
    from: userAddress,
    functionSignature,
  }

  const dataToSignObj = {
    types: {
      EIP712Domain: domainType,
      MetaTransaction: metaTransactionType,
    },
    domain: domainData,
    primaryType: 'MetaTransaction',
    message,
  }

  const dataToSign = isVenly ? dataToSignObj : JSON.stringify(dataToSignObj)

  return dataToSign
}

export const metaCall = async (contract, contractInterface, account, walletProvider, salt, call) => {
  const nonce = await contract.getNonce(account);
  const functionSignature = contractInterface.encodeFunctionData(call.name, call.params);

  const dataToSign = getMetaTransactionEIP712SignConfig(
    salt,
    account,
    'BuyPolicyMetaTransaction',
    await contract.address,
    functionSignature,
    nonce
  )
  const signature = await walletProvider.send('eth_signTypedData_v3', [account, dataToSign]);
  const { r, s, v } = getSignatureParameters(signature);

  const tx = await contract.executeMetaTransaction(account, functionSignature, r, s, v);
  return tx;
};
