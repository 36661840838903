import { ethers } from 'ethers'
import { simpleRpcProvider } from 'utils/providers'
import { poolsConfig } from 'config/constants'
import { Address, PoolConfig } from 'config/constants/types'

// Addresses
import { getAddress, getCohortAddress, getSSRPAddress, getCapitalAgentAddress, getSalesPolicyAddress } from 'utils/addressHelpers'

// ABI
import bep20Abi from 'config/abi/erc20.json'
import CohortAbi from 'config/abi/Cohort.json'
import poolAbi from 'config/abi/RiskPool.json'
import poolAbi2 from 'config/abi/RiskPool.json'
import ssipAbi from 'config/abi/SSIPAbi.json'
import premiumAbi from 'config/abi/PremiumPool.json'
import lpTokenAbi from 'config/abi/lpTokenAbi.json'
import lpPoolAbi from 'config/abi/lpPoolAbi.json'
import capitalAgentAbi from 'config/abi/capitalAgent.json'
import { premiumPool } from 'config/constants/pools'
import SalesPolicyAbi from 'config/abi/salesPolicy.json';

const emptyAddress: Address = {
  1: '',
  4: '',
  56: '',
  97: '',
  2221: '',
}

const getContract = (abi: any, address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  const signerOrProvider = signer ?? simpleRpcProvider
  return new ethers.Contract(address, abi, signerOrProvider)
}

export const getBep20Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, address, signer)
}
export const getCohortContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(CohortAbi, getCohortAddress(), signer)
}

// export const getRiskPoolsContract = (addr: string, signer?: ethers.Signer | ethers.providers.Provider) => {
//   return getContract(poolAbi, addr, signer)
// }

export const getRiskPoolsContract = (id: number, signer?: ethers.Signer | ethers.providers.Provider) => {
  const config: PoolConfig | undefined = poolsConfig.find((pool) => pool.poolId === id)
  return id === 0
    ? getContract(poolAbi2, getAddress(config ? config.contractAddress : emptyAddress), signer)
    : getContract(poolAbi, getAddress(config ? config.contractAddress : emptyAddress), signer)
}

export const getPremiumContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(premiumAbi, getAddress(premiumPool.contractAddress), signer)
}

export const getSSRPContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(ssipAbi, getSSRPAddress(), signer)
}

export const getCapitalAgentContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(capitalAgentAbi, getCapitalAgentAddress(), signer)
}

export const getLpContract = (address: Address, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(lpTokenAbi, getAddress(address), signer)
}

export const getLpPoolContract = (address: Address, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(lpPoolAbi, getAddress(address), signer)
}

export const getPoolContract = (address: Address, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(ssipAbi, getAddress(address), signer)
}

export const getSalesPolicyContract = (signer) => {
  return getContract(SalesPolicyAbi, getSalesPolicyAddress(), signer);
};
