import { useState, useEffect } from 'react';
import { fetchPTypeList } from 'utils/apiHelpers';


export const useGetPTypeListWithPagination = (page: number, limit: number, updated: boolean) => {
  const [data, setData] = useState<any>([]);
  useEffect(() => {
    const fetch = async () => {
      const res: any = await fetchPTypeList(page, limit);
      if (res?.success){
        setData(res?.data)
      }
    }
    fetch();  
  }, [page, limit, updated]);

  return data;
}
