import { createSlice, Dispatch } from '@reduxjs/toolkit'
import poolsConfig, { protocols } from 'config/constants/pools'
import { AppDispatch } from 'state'
import { Pool, AppThunk, PremiumPool } from 'state/types'
import { fetchPoolsTotalStaking, fetchPoolsMaxSize, fetchPremiumPoolBalanceByProtocal, fetchPoolInfos } from './fetchPools'

const initialState: any = {
  data: [...poolsConfig],
  premiumData: [...protocols],
  userDataLoaded: false,
  ssipPoolInfo: {
    accUnoPerShare: {
      type: "BigNumber",
      hex: "0x00",
    },
    lastRewardBlock: {
      type: "BigNumber",
      hex: "0x00",
    },
    unoMultiplierPerBlock: {
      type: "BigNumber",
      hex: "0x00",
    },
  },
}

// Thunks
export const fetchPoolsPublicDataAsync = () => async (dispatch) => {
  const totalStakings = await fetchPoolsTotalStaking()
  const maxSizes = await fetchPoolsMaxSize()
  const poolInfos = await fetchPoolInfos()

  const liveData = poolsConfig.map((pool) => {
    const totalStaking = totalStakings.find((entry) => entry.poolId === pool.poolId)
    const maxSize = maxSizes.find((entry) => entry.poolId === pool.poolId)
    const rewardPerBlock = poolInfos.find((entry) => entry.poolId === pool.poolId)
    return {
      ...totalStaking,
      ...maxSize,
      ...rewardPerBlock,
    }
  })

  dispatch(setPoolsPublicData(liveData))
}

export const updateTotalStakeInPool =
  (poolId: number): AppThunk =>
  async (dispatch) => {
    const totalStakings = await fetchPoolsTotalStaking()
    const totalStaking = totalStakings.find((entry) => entry.poolId === poolId)
    dispatch(updatePoolsPublicData({ poolId, field: 'totalStaked', value: totalStaking }))
}

export const fetchPremiumPoolsPublicDataAsync = (): AppThunk => async (dispatch) => {
  const totalStakings = await fetchPremiumPoolBalanceByProtocal()
  const liveData = protocols.map((pool) => {
    const totalStaking = totalStakings.find((entry) => entry.protocolId === pool.protocolId)

    return {
      ...totalStaking,
    }
  })

  dispatch(setPremiumPoolsPublicData(liveData))
}

export const PoolsSlice = createSlice({
  name: 'Pools',
  initialState,
  reducers: {
    setPoolsPublicData: (state, action) => {
      const livePoolsData: Pool[] = action.payload
      state.data = state.data.map((pool) => {
        const livePoolData = livePoolsData.find((entry) => entry.poolId === pool.poolId)
        return { ...pool, ...livePoolData }
      })
    },
    updatePoolsPublicData: (state, action) => {
      const { field, value, poolId } = action.payload
      const index = state.data.findIndex((p) => p.poolId === poolId)
      if (index >= 0) {
        state.data[index] = { ...state.data[index], [field]: value[`${field}`] }
        return state
      }
    },
    setPremiumPoolsPublicData: (state, action) => {
      const livePoolsData: PremiumPool[] = action.payload
      state.premiumData = state.premiumData.map((pool) => {
        const livePoolData = livePoolsData.find((entry) => entry.protocolId === pool.protocolId)
        return { ...pool, ...livePoolData }
      })
    },
  },
})

// Actions
export const { setPoolsPublicData, updatePoolsPublicData, setPremiumPoolsPublicData } = PoolsSlice.actions

export default PoolsSlice.reducer
