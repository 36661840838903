export default {
  cohort: {
    1: '0x87e1f628225c170a5C0Bf895580686430DEb3322',
    // 4: '0x6ab6eEb57C3069c22F7319ad0d12a2A565D4977A',
    5:'0x57fFdCCDf010262a358DA540a991F5ACCe010CAE',
    56: '0xa7c8Dd26eB99534Dd848278e53CB7e260Ea83CE1',
    97: '0xA77997Be31Fc479743fb71ae8f143670ECe5546C',
    
  },
  multiCall: {
    1: '0x1F98415757620B543A52E61c46B32eB19261F984',
    
    5:'',
    56: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    97: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576'
  },
  premium: {
    1: '0x87e1f628225c170a5C0Bf895580686430DEb3322',
    
    5:'0xf18bBfF301c8602DeA7EC739fECDDE9690fF9f3B',
    56: '0xd85D5144718982bdD442E67d9810151a6AdF31c2',
    97: '0xa72cf075482214288725330aeb5F08a063c45026'
  },
  capitalAgent: {
    1: '0x0bCed28f17a0c8CB66c07dD1a4ccfb2ef3159c05',
    
    5: '0xa50F3fD32d7Ead49a5C34091744bE516b67417cA',
    56: '0x62e1D28f3204962852976983cD575Fc2741bfE19',
    97: '0xF2175d108288df0d0b657173B05EFd3A1e3cEAc6'
  },
  ssrp: {
    1: '0x87e1f628225c170a5C0Bf895580686430DEb3322',
    
    5:'0x57fFdCCDf010262a358DA540a991F5ACCe010CAE',
    56: '0x62e1D28f3204962852976983cD575Fc2741bfE19',
    97: '0xC606C1c1d3d2aB9c16d911C6eA20C3A4B8d26149'
  },
  uno: {
    1: '0x474021845C4643113458ea4414bdb7fB74A01A77',
    
    5:'0x491e7B202Ca6eB8beFb5eC4C6a3D68ce2159dcF2',
    56: '0x62e1D28f3204962852976983cD575Fc2741bfE19',
    97: '0x41bA390295F266E38AB3C904D9cbeF5ad9F85424'
  },
  usdc: {
    1: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    5:'0xb1Ce55d27FaF8D4499b840A3EDf509E4df43f9E1',  
    56: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    97: '0x2985F2F8Ffa19Aa74008A9a1331da69A57d231E1',
    2221: '0xCe86b34aE48947794F038a2c1C2BA577bC4B03a4'
  },
  salesPolicy: {
    1: '0xdD2715Ec8C6D96E3064063842C47413782C4F66b',
    
    5:'0xA23768180e1F50577bf8B7854eFb9EDb7c73Bb1F',
    56: '0xd85D5144718982bdD442E67d9810151a6AdF31c2',
    97: '0x8a88f5a0ebc56e7ee94a0d8045ce4d873bba51f0'
  },
}
