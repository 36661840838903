import { request, gql } from 'graphql-request'
import { getRewardAddress } from './addressHelpers'

const GRAPH_API = 'https://api.thegraph.com/subgraphs/name/snowwhitedev/unore-v1-subgraph'
const PREMIUM_GRAPH_API = 'https://api.thegraph.com/subgraphs/name/luckywebdev/prermium-data-subgraph'
export const COVER_GRAPH_ENDPOINTS = 'https://api.thegraph.com/subgraphs/name/luckywebdev/cover-portal-subgraph'

export const getPremiumWeekData = async (poolId: number): Promise<any> => {
  const rewardAddress = getRewardAddress(poolId);
  const response = await request(
    PREMIUM_GRAPH_API,
    gql`
      query getPremiumWeekData($rewarder: String!) {
        premiumDistributionWeekDatas(where: { rewarder: $rewarder }) {
          id
          date
          tvl
        }
      }
    `,
    { rewarder: rewardAddress.toLowerCase() },
  )
  return response.premiumDistributionWeekDatas
}

export const getTotalPremiumData = async (poolId: number): Promise<any> => {
  const rewardAddress = getRewardAddress(poolId);
  const response = await request(
    PREMIUM_GRAPH_API,
    gql`
      query getTotalPremiumData($rewarder: String!) {
        premiumDistributionPositions(where: { rewarder: $rewarder }) {
          id
          tvl
        }
      }
    `,
    { rewarder: rewardAddress.toLowerCase() },
  )
  return response.premiumDistributionPositions
}

export const getPremiumBalance = async (): Promise<any> => {
  const response = await request(
    GRAPH_API,
    gql`
      query getPremiumBalance {
        premiumPositions {
          id
          premiumBalance
        }
      }
    `,
  )
  return response.premiumPositions
}

export const getRiskPoolsTotalSupply = async () => {
  const response = await request(
    GRAPH_API,
    gql`
      query getPoolsTotalSupply {
        riskPools(first: 3) {
          id
          totalSupply
        }
      }
    `,
  )
  return response.riskPools
}

export const getTvlCoverage = async () => {
  const response = await request(
    COVER_GRAPH_ENDPOINTS,
    gql`
      query getTvlCoverage {
        utilizedPositions {
          id
          tvl
        }
      }
    `
  )
  return response.utilizedPositions
}

export const getPremiumTvl = async () => {
  const response = await request(
    COVER_GRAPH_ENDPOINTS,
    gql`
      query getPremiumTvl {
        totalPremiumPositions {
          id
          tvl
        }
      }
    `
  )
  return response.totalPremiumPositions
}
