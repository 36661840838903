import { useState, useEffect } from 'react';
import { fetchChainListAll, fetchChainList } from 'utils/apiHelpers';

export const useGetChainListAll = (updated = false) => {
  const [data, setData] = useState<any>([]);
  useEffect(() => {
    const fetch = async () => {
      const res: any = await fetchChainListAll();
      if (res?.success){
        setData(res?.data)
      }
    }
    fetch();  
  }, [updated]);

  return data;
}

export const useGetChainListWithPagination = (page: number, limit: number, updated: boolean) => {
  const [data, setData] = useState<any>([]);
  useEffect(() => {
    const fetch = async () => {
      const res: any = await fetchChainList(page, limit);
      if (res?.success){
        setData(res?.data)
      }
    }
    fetch();  
  }, [page, limit, updated]);

  return data;
}
