import React, { useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import { setupNetwork } from 'utils/wallet'
import Login from './pages/auth'
import Dashboard from './pages/home'
import ChainsControl from './pages/chainControl'
import TokenControl from './pages/tokenControl'
import ProductType from './pages/productType'
import Addresses from 'pages/addresses'
import Whitelist from 'pages/whitelist'
import './App.css'
import Menu from 'components/Menu'
import { useAuthState } from 'state/auth/hooks'
import Policies from 'pages/policies'

function App() {
  const { chainId, account } = useWeb3React()
  const { userName } = useAuthState()

  useEffect(() => {
    const init = async () => {
      await setupNetwork()
    }
    if (account && chainId !== 1) {
      init()
    }
  }, [account, chainId])

  window.ethereum?.removeAllListeners(['networkChanged'])

  return (
    <div className="App">
      <BrowserRouter>
        {userName === '' && (
          <Routes>
            <Route path="/" element={<Login />} />
          </Routes>
        )}
        {userName !== '' && (
          <Menu>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/policies" element={<Policies />} />
              <Route path="/chains" element={<ChainsControl />} />
              <Route path="/tokens" element={<TokenControl />} />
              <Route path="/product-type" element={<ProductType />} />
              <Route path="/whitelist" element={<Whitelist />} />
              <Route path="/blacklist" element={<Addresses />} />
            </Routes>
          </Menu>
        )}
      </BrowserRouter>
    </div>
  )
}

export default App
