import { poolsConfig } from 'config/constants'
import addresses from 'config/constants/contracts'
import { Address, PoolConfig } from 'config/constants/types'
import { ChainId } from 'config/constants'

const emptyAddress: Address = {
  1: '',
  5: '',
  56: '',
  97: '',
  2221: ''
}

export const getAddress = (address: Address) : string => {
  return address ? address[ChainId.MAINNET] : address[ChainId.MAINNET]
}

export const getMulticallAddress = () => {
  return getAddress(addresses.multiCall)
}
export const getCohortAddress = () => {
  return getAddress(addresses.cohort)
}

export const getPremiumPoolAddress = () => {
  return getAddress(addresses.premium)
}

export const getRiskPoolAddress = (poolId: number) => {
  const poolConfig: PoolConfig | undefined = poolsConfig.find((p) => p.poolId === poolId)
  return getAddress(poolConfig ? poolConfig.contractAddress : emptyAddress)
}

export const getSSRPAddress = () => {
  return getAddress(addresses.ssrp)
}

export const getUnoAddress = () => {
  return getAddress(addresses.uno)
}

export const getCapitalAgentAddress = () => {
  return getAddress(addresses.capitalAgent)
}

export const getRewardAddress = (poolId: number) => {
  const pool: PoolConfig | undefined = poolsConfig.find((p) => p.poolId === poolId)
  return getAddress(pool && pool?.rewardAddress? pool.rewardAddress : emptyAddress)
}

export const getTokenAddress = (symbol: string) => {
  return getAddress(addresses[`${symbol}`]);
}

export const getSalesPolicyAddress = () => {
  return getAddress(addresses.salesPolicy)
}