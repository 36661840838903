import { useState, useEffect } from 'react'
import { getTvlCoverage, getPremiumTvl } from 'utils/graphHelpers'

const useGetTvlCoverageAmount = () => {
  const [tvl, setTvl] = useState(0)

  useEffect(() => {
    const fetch = async () => {
      const res = await getTvlCoverage()
      if (res[0]) {
        setTvl(Number(res[0].tvl))
      }
    }
    fetch()
  }, [])

  return tvl
}

export const useGetTvlPremiumCollected = () => {
  const [tvl, setTvl] = useState(0)

  useEffect(() => {
    const fetch = async () => {
      const res = await getPremiumTvl()
      if (res[0]) {
        setTvl(Number(res[0].tvl))
      }
    }
    fetch()
  }, [])

  return tvl
}

export default useGetTvlCoverageAmount
