import React from 'react'
import { Grid, Typography } from '@mui/material'
import Logo from 'assets/images/unore-logo.png'

const MainHeader = ({subText}) => {
  
  return (
    <Grid container spacing={0} sx={{ justifyContent: 'space-between' }}>
      <Grid item sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img src={Logo} alt="UnoRe" />
        <Typography color="textPrimary" variant="h4" sx={{ py: 2, marginLeft: 1, mt: 1 }}>
          {`Admin Portal - ${subText}`}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default MainHeader
