import React, { useState, useEffect } from 'react'
import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Autocomplete,
  TextField,
  AlertColor,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/material/styles'
import { addToken } from 'utils/apiHelpers'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

interface ModalProps {
  open: boolean
  chainData: number
  handleClose: () => void
  handleOpenSnackBar: (type: AlertColor, msg: string) => void
}

interface ChainType {
  _id?: string
  chain_id?: string
  chain_name?: string
  chain_logo?: string
}
const InputItem: React.FC<{
  label: string
  placeholder?: string
  value?: string | number
  error?: boolean
  disabled?: boolean
  handleChange?: (e: any) => void
}> = ({ label, placeholder, value, error, disabled, handleChange }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
      <Typography gutterBottom sx={{ minWidth: 80 }}>{`${label} :`}</Typography>
      <TextField
        value={value}
        error={error}
        disabled={disabled}
        fullWidth
        size="small"
        placeholder={placeholder}
        variant="outlined"
        sx={{ marginLeft: 5, minWidth: 250 }}
        onChange={handleChange}
      />
    </Box>
  )
}

const ChainSelectBox: React.FC<{ data: any, handleSelectChains?: (e: any, v: any) => void }> = ({ data, handleSelectChains }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
      <Typography gutterBottom sx={{ minWidth: 70 }}>
        Chain :
      </Typography>
      <Autocomplete
        limitTags={2}
        id="multiple-limit-tags"
        options={data}
        getOptionLabel={(option) => option.chain_name}
        defaultValue={data[0]}
        renderInput={(params) => <TextField {...params} label="Supported chain" placeholder="chains" />}
        fullWidth
        sx={{ marginLeft: 5, minWidth: 250 }}
        size = "small"
        onChange={handleSelectChains}
      />
    </Box>
  )
}

const AddTokenDlg: React.FC<ModalProps> = ({ open, chainData, handleClose, handleOpenSnackBar }) => {
  const [tokenName, setTokenName] = useState('')
  const [tokenID, setTokenID] = useState<string>('')
  const [logo, setLogo] = useState('')
  const [cmcId, setCmcId] = useState('')
  const [geckoId, setGeckoId] = useState('')
  const [address, setTokenAddress] = useState('')
  const [chains, setChains] = useState<ChainType>()

  useEffect(() => {
    if (chainData) {
      setChains(chainData[0])
    }
  }, [chainData])

  const handleAddToken = () => {
    if (!validation()) {
      return
    }
    const body = {
      token_id: tokenID,
      token_name: tokenName,
      token_logo: logo,
      token_cmcId: cmcId,
      token_gecko_id: geckoId,
      token_addr: [
        {
          chain_id: chains.chain_id,
          token_addr: address
        }
      ]
    }
    addToken(body)
      .then((res: any) => {
        if (res.success) {
          handleOpenSnackBar('success', res.message)
          handleClose()
        } else {
          handleOpenSnackBar('error', res.message)
          handleClose()
        }
      })
      .catch((err: any) => {
        handleOpenSnackBar('error', err.message)
        handleClose()
      })
  }

  const validation = () => {
    if (tokenID === '') {
      handleOpenSnackBar('warning', 'Token id is required.')
      return false
    }
    if (tokenName === '') {
      handleOpenSnackBar('warning', 'Token name is required.')
      return false
    }
    if (logo === '') {
      handleOpenSnackBar('warning', 'Token logo link is required.')
      return false
    }
    return true
  }
  const handleSelectChains = (e: any, v: ChainType) => {
    setChains(v)
  }
  return (
    <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle
        sx={{ m: 0, p: 2, textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <div />
        <Typography variant="h5">Add a new token</Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <InputItem
          label="TokenID*"
          error={tokenID === ''}
          placeholder="Token id"
          value={tokenID}
          handleChange={(e) => setTokenID(e.target.value)}
        />
        <InputItem
          label="Name*"
          placeholder="Token name"
          error={tokenName === ''}
          handleChange={(e) => setTokenName(e.target.value)}
        />
        <InputItem
          label="Logo*"
          placeholder="Token logo link"
          error={logo === ''}
          handleChange={(e) => setLogo(e.target.value)}
        />
        <InputItem
          label="CMCID"
          placeholder="Coinmarketcapp id"
          handleChange={(e) => setCmcId(e.target.value)}
        />
        <InputItem
          label="GeckoID"
          placeholder="Coingecko id"
          handleChange={(e) => setGeckoId(e.target.value)}
        />
        <InputItem
          label="Address"
          placeholder="Token address"
          handleChange={(e) => setTokenAddress(e.target.value)}
        />
        <ChainSelectBox data={chainData} handleSelectChains={handleSelectChains} />
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Box sx={{ py: 2 }}>
          <Button autoFocus color="primary" variant="contained" onClick={handleAddToken}>
            Add Token
          </Button>
        </Box>
      </DialogActions>
    </BootstrapDialog>
  )
}

export default AddTokenDlg
