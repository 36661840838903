import React from 'react'
import { Card, CardContent, Grid, Typography } from '@mui/material';

interface CardProps {
  label?: string;
  value?: string | number;
}

const CarouselCard: React.FC<CardProps> = ({ label, value}) => {
  return (
    <Card sx={{ height: '100%'}}>
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: 'center'}}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="body1">
              {label}
            </Typography>
            <Typography color="textPrimary" variant="h5">
              {value}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default CarouselCard
