import { useState, useEffect } from 'react';
import { getBlacklistAll } from 'utils/apiHelpers';

export const useGetBlackList = (page, limit, updated?: boolean) => {
  const [data, setData] = useState<any>({bladdresses: [], totalItems: 0});

  useEffect(() => {
    const fetch = async () => {
      const res: any = await getBlacklistAll(page, limit);
      if (res?.success){
        setData(res?.data)
      }
    }
    fetch();  
  }, [page, limit, updated]);

  return data;
}
