import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';
import { metaCall } from '../biconomy';
import salesPolicyAbi from 'config/abi/salesPolicy.json';

const usdcDecimals = 6;
const secondsInOneDay = 24 * 3600;

export const getBigNumberWithDecimal = (value: number, decimal = usdcDecimals) => {
  return (new BigNumber(value).multipliedBy(10 ** decimal).toString()).split('.')[0]
}
const buySalesPolicy = async (contract, param, walletProvider, account, chainId) => {
  const { protocols, coverPrice, coverAmount, coverDuration, signature, deadline, usdcAddress, assetAddresses } = param;
  const bigCoverPrice = getBigNumberWithDecimal(coverPrice);

  const bigCoverAmount = [];
  const coverDurationInSec = [];

  coverAmount.forEach((element, index) => {
    bigCoverAmount.push((new BigNumber(element).multipliedBy(10 ** usdcDecimals).toString()).split('.')[0]);
    coverDurationInSec.push(new BigNumber(coverDuration[index] * secondsInOneDay).toString());
  });

  const funParam = [
    assetAddresses,
    protocols,
    bigCoverAmount,
    coverDurationInSec,
    bigCoverPrice,
    new BigNumber(deadline).toString(),
    usdcAddress,
    signature.r,
    signature.s,
    signature.v
  ];

  const contractInterface = new ethers.utils.Interface(salesPolicyAbi);
  const tx = await metaCall(contract, contractInterface, account, walletProvider, chainId, {
    name: 'buyPolicy',
    params: funParam,
  });

  return tx;
}

export default buySalesPolicy;
