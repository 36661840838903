import { useDropzone } from 'react-dropzone'
import React, { useCallback, useState } from 'react'
import { checkExistingFile, uploadLogo } from '../utils/apiHelpers'
import { Box, Button, Typography } from '@mui/material'

type LogoFileZoneProps = {
  currentLogo?: string
  onImageChange: (image: string) => void
}

export function LogoFileZone({ currentLogo, onImageChange }: LogoFileZoneProps) {
  const [isExistingFile, setIsExistingFile] = useState<boolean>(false)
  const [currentImage, setCurrentImage] = useState<string>('')

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles[0]

      const existingFile = await checkExistingFile(file.name)

      if (existingFile) {
        setCurrentImage(existingFile)
        setIsExistingFile(true)
      } else {
        const newFile = await uploadLogo({
          file,
        })

        setCurrentImage(newFile.data.url)
        onImageChange(newFile.data.url)
      }
    },
    [onImageChange],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <>
      <div
        {...getRootProps()}
        style={{
          borderRadius: '50%',
          width: '100px',
          height: '100px',
          border: '2px dashed #ccc',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          position: 'relative',
          cursor: 'pointer',
        }}
      >
        <input {...getInputProps()} />
        {currentImage || currentLogo ? (
          <img
            src={currentImage || currentLogo}
            alt="logo"
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        ) : (
          <p style={{ fontSize: '12px', textAlign: 'center' }}>
            {isDragActive ? 'Drop file here ...' : 'Drag or click to select a logo'}
          </p>
        )}
      </div>
      {isExistingFile && (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="body2" color="error" textAlign="center">
            A logo with the same name already exists. <br />
            Do you want to use the existing logo?
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
            <Button
              onClick={() => {
                onImageChange(currentImage)
                setIsExistingFile(false)
              }}
              variant="contained"
              color="success"
              size="small"
            >
              Yes
            </Button>
            <Button
              onClick={() => {
                setIsExistingFile(false)
                setCurrentImage('')
              }}
              variant="outlined"
              size="small"
              color="error"
            >
              No
            </Button>
          </Box>
        </Box>
      )}
    </>
  )
}
