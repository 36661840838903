import React, { useEffect, useState } from 'react'
import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Autocomplete,
  AlertColor,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/material/styles'
import { addProtocol } from 'utils/apiHelpers'
import { LogoFileZone } from 'components/LogoFileZone'

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

interface ModalProps {
  open: boolean
  chainData: any[]
  handleClose: () => void
  handleOpenSnackBar: (type: AlertColor, msg: string) => void
}

export const InputItem: React.FC<{
  label: string
  placeholder?: string
  value?: string | number
  error?: boolean
  handleChange?: (e: any) => void
}> = ({ label, placeholder, value, error, handleChange }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
      <Typography gutterBottom sx={{ minWidth: 70 }}>{`${label} :`}</Typography>
      <TextField
        error={error}
        fullWidth
        size="small"
        placeholder={placeholder}
        variant="outlined"
        sx={{ marginLeft: 5, minWidth: 250 }}
        onChange={handleChange}
      />
    </Box>
  )
}

export const NumberInputItem: React.FC<{
  label: string
  placeholder?: string
  value?: string | number
  error?: boolean
  handleChange?: (e: any) => void
}> = ({ label, placeholder, value, error, handleChange }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
      <Typography gutterBottom sx={{ minWidth: 70 }}>{`${label} :`}</Typography>
      <TextField
        error={error}
        fullWidth
        size="small"
        type="number"
        placeholder={placeholder}
        variant="outlined"
        sx={{ marginLeft: 5, minWidth: 250 }}
        onChange={handleChange}
      />
    </Box>
  )
}

export const TypeSelectBox: React.FC<{ handleSelectTypes?: (e: any, v: any) => void }> = ({ handleSelectTypes }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
      <Typography gutterBottom sx={{ minWidth: 70 }}>
        Type* :
      </Typography>
      <Autocomplete
        fullWidth
        disablePortal
        size="small"
        id="select-box-demo"
        options={['0', '1', '2', '3']}
        sx={{ marginLeft: 5, minWidth: 250 }}
        renderInput={(params) => <TextField {...params} label="Product Type" />}
        onChange={handleSelectTypes}
      />
    </Box>
  )
}

export const StatusSelectBox: React.FC<{ handleSelectStatus?: (e: any, v: any) => void }> = ({
  handleSelectStatus,
}) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
      <Typography gutterBottom sx={{ minWidth: 70 }}>
        Status :
      </Typography>
      <Autocomplete
        fullWidth
        disablePortal
        defaultValue="Active"
        size="small"
        id="select-box-demo"
        options={['Inactive', 'Active']}
        sx={{ marginLeft: 5, minWidth: 250 }}
        renderInput={(params) => <TextField {...params} label="Protocol Status" />}
        onChange={handleSelectStatus}
      />
    </Box>
  )
}

export const ChainSelectBox: React.FC<{ data: any; handleSelectChains?: (e: any, v: any) => void }> = ({
  data,
  handleSelectChains,
}) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
      <Typography gutterBottom sx={{ minWidth: 70 }}>
        Chains* :
      </Typography>
      <Autocomplete
        multiple
        limitTags={2}
        id="multiple-limit-tags"
        options={data}
        getOptionLabel={(option) => option.chain_name}
        defaultValue={[data[0]]}
        renderInput={(params) => <TextField {...params} label="Supported chains" placeholder="chains" />}
        fullWidth
        sx={{ marginLeft: 5, minWidth: 250 }}
        size="small"
        onChange={handleSelectChains}
      />
    </Box>
  )
}

const AddProtocolModal: React.FC<ModalProps> = ({ open, chainData, handleClose, handleOpenSnackBar }) => {
  const [pId, setPId] = useState('')
  const [pName, setPName] = useState('')
  const [link, setLink] = useState('')
  const [logo, setLogo] = useState('')
  const [factor, setPFactor] = useState(0)
  const [chains, setChains] = useState<any[]>()
  const [protocolType, setProtocolType] = useState<number>(1)
  const [protocolStatus, setProtocolStatus] = useState<number>(1)
  const [symbol, setSymbol] = useState<string>('')
  const [description, setDescription] = useState<string>('')

  const handleSelectChains = (e: any, v: string[]) => {
    setChains(v)
  }

  useEffect(() => {
    if (chainData) {
      setChains([chainData[0]])
    }
  }, [chainData])

  const handleSelectTypes = (e: any, v: string) => {
    setProtocolType(Number(v))
  }

  const handleSelectStatus = (e: any, v: string) => {
    setProtocolStatus(v === 'Active' ? 1 : 0)
  }

  const handleAddProtocol = () => {
    if (!validation()) {
      return
    }
    const body = {
      protocol_id: pId,
      product_name: pName,
      product_logo: logo,
      product_symbo: symbol,
      product_description: description,
      product_link: link,
      premium_factor: factor,
      product_type: protocolType,
      status: protocolStatus,
      DAO_approval_status: 3,
      chains: chains.map((_i: any) => _i.chain_id),
    }

    addProtocol(body)
      .then((res: any) => {
        if (res.success) {
          handleOpenSnackBar('success', res.message)
          handleClose()
        } else {
          handleOpenSnackBar('error', res.message)
          handleClose()
        }
      })
      .catch((err: any) => {
        handleOpenSnackBar('error', err.message)
        handleClose()
      })
  }

  const validation = () => {
    if (pId === '' || pId.length !== 42) {
      handleOpenSnackBar('warning', 'Protocol Id is not valid.')
      return false
    }
    if (pName === '') {
      handleOpenSnackBar('warning', 'Protocol name is required.')
      return false
    }
    if (link === '') {
      handleOpenSnackBar('warning', 'Protocol link is required.')
      return false
    }
    if (logo === '') {
      handleOpenSnackBar('warning', 'Protocol logo is required.')
      return false
    }
    if (factor === 0) {
      handleOpenSnackBar('warning', 'Premium factor is required.')
      return false
    }
    return true
  }

  return (
    <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle
        sx={{ m: 0, p: 2, textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <div />
        <Typography variant="h5">Add a new protocol</Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <InputItem
          label="ID*"
          placeholder="0x0000000000000000000000000000000000000000"
          error={pId === ''}
          handleChange={(e) => setPId(e.target.value)}
        />
        <InputItem
          label="Name*"
          placeholder="Protocol name"
          error={pName === ''}
          handleChange={(e) => setPName(e.target.value)}
        />
        <InputItem
          label="Link*"
          placeholder="Protocol link"
          error={link === ''}
          handleChange={(e) => setLink(e.target.value)}
        />
        <NumberInputItem
          error={factor === 0}
          label="Factor*"
          placeholder="Premium factor"
          handleChange={(e) => setPFactor(Number(e.target.value))}
        />
        <InputItem label="Symbol" placeholder="Protocol symbol" handleChange={(e) => setSymbol(e.target.value)} />
        <InputItem
          label="Desc"
          placeholder="Protocol description"
          handleChange={(e) => setDescription(e.target.value)}
        />
        <TypeSelectBox handleSelectTypes={handleSelectTypes} />
        <ChainSelectBox data={chainData} handleSelectChains={handleSelectChains} />
        <StatusSelectBox handleSelectStatus={handleSelectStatus} />
        <Typography gutterBottom sx={{ minWidth: 60 }}>
          Logo
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <LogoFileZone
            onImageChange={(url) => {
              setLogo(url)
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Box sx={{ py: 2 }}>
          <Button autoFocus color="primary" variant="contained" onClick={handleAddProtocol}>
            Add Protocol
          </Button>
        </Box>
      </DialogActions>
    </BootstrapDialog>
  )
}

export default AddProtocolModal
