import React, { useState, useEffect } from 'react'
import { Box, Grid, Container } from '@mui/material'
import { useGetPoolsData } from 'state/pools/hooks'
import CarouselCard from './CarouselCard'
import { useGetTvlPremiumCollected } from 'hooks/useGetTvlCoverageAmount'
import useGetAvailableCoverAmountInfos from 'hooks/useGetAvailableCoverAmountInfos'
import { useGetUSDCAmtForOneDollar } from 'hooks/useGetPremiumPrices'
import { formatFixedNumber2, getBalanceNumberByDecimal } from 'utils/formatBalance'
import { useGetUnoreUsdPrice } from 'hooks/useGetUnoreUsdPrice'
import { shortenNumber } from 'utils'

const Carousel = () => {
  const [ttCapital, setTTCapital] = useState(0)
  // const [temp, setTemp] = useState([])

  const { data, premiumData } = useGetPoolsData()
  // const tvlCoverage = useGetTvlCoverageAmount()
  const tvlPremium = useGetTvlPremiumCollected()
  const availabeInfos = useGetAvailableCoverAmountInfos();
  const { usdcAmtForOneDollar } = useGetUSDCAmtForOneDollar();
  const usdPrices = useGetUnoreUsdPrice()

  const availableAmount1 = availabeInfos
    ? formatFixedNumber2(availabeInfos.totalCapitalStaked * availabeInfos.mlr - availabeInfos.totalUtilizedAmount, 6) < 0
    ? 0 : formatFixedNumber2(availabeInfos.totalCapitalStaked * availabeInfos.mlr - availabeInfos.totalUtilizedAmount, 6)
    : 0
  const availableAmount = usdcAmtForOneDollar && usdcAmtForOneDollar > 0 ? availableAmount1 / usdcAmtForOneDollar: 0

  const mcr1 = availabeInfos
    ? formatFixedNumber2(availabeInfos.totalCapitalStaked * availabeInfos.mcr, 6)
    : 0
  const mcr = usdcAmtForOneDollar && usdcAmtForOneDollar > 0 ? mcr1 / usdcAmtForOneDollar: 0

  let ttPremium = tvlPremium
  // const ttActive = 3500000

  useEffect(() => {
    const init = () => {
      let capitalTemp = 0
      let arrTemp = []
      data.forEach((element, index) => {
        const balanceDecimal = getBalanceNumberByDecimal(element.totalStaked, element.stakingToken.decimals)

        const oneVal = usdPrices ? usdPrices[`${element.stakingToken.symbol}`] * balanceDecimal : 0
        capitalTemp += oneVal
        arrTemp.push(oneVal)
        if (index === data.length - 1) {
          setTTCapital(capitalTemp)
          // setTemp(arrTemp)
        }
      })
    }
    if (data) {
      init()
    }
  }, [usdPrices, data, setTTCapital])
  
  // const ttAssetsV1 = temp.length > 0 ? 8 * (temp[3] + temp[4] + temp[5]) : 0
  // const ttAssetsV2ReInsure = temp.length > 0 ? 1 * temp[2] : 0

  if (premiumData){
    premiumData.forEach(element => {
      ttPremium += getBalanceNumberByDecimal(element.totalStaked, 18)
    })
  }

  return (
    <Box
      component="main"
      sx={{
        py: 8,
        width: '100%',
      }}
    >
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item lg={2.4} sm={6} md={4} xs={12}>
            <CarouselCard label="Total Cap Available" value={`${shortenNumber(ttCapital)} USD`} />
          </Grid>
          <Grid item lg={2.4} sm={6} md={4} xs={12}>
            <CarouselCard label="Total Risk Capacity" value={`${shortenNumber(availableAmount)} USD`} />
          </Grid>
          <Grid item lg={2.4} sm={6} md={4} xs={12}>
            <CarouselCard label="Total Premium Collected" value={`${shortenNumber(ttPremium)} USD`} />
          </Grid>
          <Grid item lg={2.4} sm={6} md={4} xs={12}>
            <CarouselCard label="MCR" value={`${shortenNumber(mcr)} USD`} />
          </Grid>
          <Grid item lg={2.4} sm={6} md={4} xs={12}>
            <CarouselCard label="SCR" value={'N / A'} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default Carousel
