import { createSlice } from '@reduxjs/toolkit';

export interface AuthState {
    userName: string;
    userId: string;
    error?: boolean;
}

const initialState: AuthState = {
    userId: '',
    userName: "",
    error: false,
}

export const AuthSlice = createSlice({
    name: 'User',
    initialState,
    reducers: {
        userLoginStatus: (state, action) => {
            state = {...state, ...action.payload }
            return state
        }
    },
})

export const { userLoginStatus } = AuthSlice.actions

export default AuthSlice.reducer