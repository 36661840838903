import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Container, TextField, Typography, AlertColor } from '@mui/material'
import { signup, signin } from 'utils/apiHelpers'
import { useAppDispatch } from 'state'
import { userLoginStatus } from 'state/auth'
import CustomizedSnackbars from 'components/SnackBar'

const Login = () => {
  const navigate = useNavigate()
  const [username, setUsername] = useState('')
  const [pwd, setPassword] = useState('')
  // const [check, setCheck] = useState(false);

  const [serverity, setServerity] = useState<AlertColor>('success')
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [message, setAlertMessage] = useState('')
  const dispatch = useAppDispatch()

  const handleSubmit = async () => {
    if (!validation()) {
      return;
    }
    // if (check) {
    //   signup({
    //     user_name: username,
    //     password: pwd
    //   }).then((res: any) => {
    //     if (res.success) {
    //       dispatch(userLoginStatus({
    //         userId: 'user_id',
    //         userName: username,
    //       }))
    //       handleOpenSnackBar('success', 'Successfully registered.')
    //       setTimeout(() => {
    //         navigate('/dashboard')
    //       }, 1000)
    //     } else {
    //       handleOpenSnackBar('error', res.message)
    //     }
    //   })
    // } else {
      signin({
        user_name: username,
        password: pwd
      }).then((res: any) => {
        if (res.success) {
          dispatch(userLoginStatus({
            userId: 'user_id',
            userName: username,
          }))
          handleOpenSnackBar('success', 'Successfully Logged In.')
          setTimeout(() => {
            navigate('/dashboard')
          }, 1000)
        } else {
          handleOpenSnackBar('error', res.message)
        }
      })
    // }
    // 
  }

  const validation = () => {
    if (username === '') {
      handleOpenSnackBar('warning', "User name is required.")
      return false
    }
    if (pwd === '') {
      handleOpenSnackBar('warning', "Password is required.")
      return false
    }
    return true;
  }

  const handleUserName = (e: any) => {
    const { value } = e.target
    setUsername(value)
  }
  const handlePassword = (e: any) => {
    const { value } = e.target
    setPassword(value)
  }

  // const handleCheck = () => {
  //   setCheck(!check)
  // }

  const handleSnackBarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackBarOpen(false)
  }
  
  const handleOpenSnackBar = (type: AlertColor, msg: string) => {
    setServerity(type)
    setAlertMessage(msg)
    setSnackBarOpen(true)
  }

  return (
    <>
      <Box
        component="main"
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          flexGrow: 1,
          minHeight: '100vh',
        }}
      >
        <Container maxWidth="sm">
          <Box>
            <Box sx={{ my: 3 }}>
              <Typography color="textPrimary" variant="h4">
                Sign in
              </Typography>
            </Box>
            <TextField
              fullWidth
              label="User Name"
              margin="normal"
              name="user name"
              onChange={handleUserName}
              type="text"
              value={username}
              variant="outlined"
            />
            <TextField
              fullWidth
              label="Password"
              margin="normal"
              name="password"
              onChange={handlePassword}
              type="password"
              value={pwd}
              variant="outlined"
            />
            <Box sx={{ py: 2 }}>
              <Button
                color="primary"
                disabled={false}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={handleSubmit}
              >
                Sign In
              </Button>
              {/* <Box sx={{ py: 2, display: 'flex', alignItems: 'center' }}>
                <Checkbox
                    checked={check}
                    color="primary"
                    onChange={handleCheck}
                  />
                <Typography color="textSecondary" variant="body2">
                  Don&apos;t have an account?{' '}
                </Typography>
              </Box> */}
            </Box>
          </Box>
        </Container>
        
        <CustomizedSnackbars
          serverity={serverity}
          snackBarOpen={snackBarOpen}
          message={message}
          handleSnackBarClose={handleSnackBarClose}
        />
      </Box>
    </>
  )
}

export default Login
