import { useMemo } from 'react';
import { useWeb3React } from '@web3-react/core';
import {
  getBep20Contract,
  getSalesPolicyContract,
} from '../utils/contractHelpers';

export const useTokenContract = (address) => {
  const { library } = useWeb3React();
  return useMemo(() => getBep20Contract(address, library?.getSigner()), [library, address]);
};

export const useSalesPolicyContract = ()  => {
  const { library } = useWeb3React();
  return useMemo(() => getSalesPolicyContract(library?.getSigner()), [library],);
};

// export const useExchangeAgentContract = () => {
//   const { library } = useWeb3React();
//   return useMemo(
//     () => getExchangeAgentContract(library?.getSigner()),
//     [library],
//   );
// };
