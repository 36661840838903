import tokens from './tokens'
import { PoolConfig, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    poolId: 0,
    stakingToken: tokens.usdcbsc,
    earningToken: tokens.uno,
    contractAddress: {
      1: '0xa476b3F7333796D4565a3D3666D54cF8557F0169',
      5: '',
      97: '0x402003901C87c73D85d8FCf4B98228d1B79faDca',
      56: '0xBD1105Ce524828f15d7da3CAF098c8E42D0Fbf31',
    },
    rewardAddress: {
      1: '0x0451863C4dc8480D95E9Fb87D73D4f427A671eB6',
      5: '',
      97: '0x71Fdc22F7522D7a07d1509205df0DEec538180f2',
      56: '0xcF88EA44CA25161CD53D4a81e5a47454900d8698', // '0x5D75249C121681578a8c387AfFd196A31Ae15c46',
    },
    riskPoolAddress: {
      1: '0x920D510D5c70C01989b66f4e24687Dddb988DdAe',
      5: '',
      97: '0xCeeDd150b9B09a865E21acA29DD5F329BaF9c49d',
      56: '0xFC9a02a13B19F65219034AB03ADcD8CAdf275f35',
    },
    poolName: 'Zeus(v2) Pool',
    lpSymbol: 'SSIP-USDC LP',
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
    distribution: 0,
    poolType: true,
    ethPool: false,
    lpPool: false,
    oldPool: false,
    isSSRP: false,
    isDisabled: false,
    stakedTokenPriceInUsd: 0,
    rewardTokenPriceInUsd: 0,
  },
  {
    poolId: 1,
    stakingToken: tokens.uno,
    earningToken: tokens.uno,
    contractAddress: {
      1: '0xa476b3F7333796D4565a3D3666D54cF8557F0169',
      5: '',
      97: '0x6C91Cde8D17D4a5FA49d4CD5DbFDF9490259B234',
      56: '0xbb5fe2d69694b44a64151eaF07199eF8420685dD',
    },
    rewardAddress: {
      1: '0x0451863C4dc8480D95E9Fb87D73D4f427A671eB6',
      5: '',
      97: '0xe2D9a972AdFcfBC4629910469C3917aAe8805Afa',
      56: '0xf7FF16fdcA5e05fEB8D1609B2b303d5ee1A888c6',
    },
    riskPoolAddress: {
      1: '0x920D510D5c70C01989b66f4e24687Dddb988DdAe',
      5: '',
      97: '0xC44EAc8E7808d5e173E8811625dfE687dB64baA2',
      56: '0x456d60a7E2a2DA97BDb43759Cf63f7acbC3a700a',
    },
    poolName: 'AresBSC Pool',
    lpSymbol: 'SSIP-USDC LP',
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
    distribution: 0,
    poolType: true,
    ethPool: false,
    lpPool: false,
    oldPool: false,
    isSSRP: false,
    isDisabled: false,
    stakedTokenPriceInUsd: 0,
    rewardTokenPriceInUsd: 0,
  },
  // {
  //   poolId: 2,
  //   stakingToken: tokens.horde,
  //   earningToken: tokens.uno,
  //   contractAddress: {
  //     1: '0xa476b3F7333796D4565a3D3666D54cF8557F0169',
  //     4: '0xd7fFE6d63f7a64260943BC0327065F13F8Ac0f47',
  //     97: '0xc75e610A640c88F6cd183247DB469Ee938C209D5',
  //     56: '0xBD1105Ce524828f15d7da3CAF098c8E42D0Fbf31',
  //   },
  //   rewardAddress: {
  //     1: '0x0451863C4dc8480D95E9Fb87D73D4f427A671eB6',
  //     4: '0x35893E75514c8e3D12603778531Ea3e383376eC9',
  //     97: '0x36137dADD65abA3747f8FE82A1E98F8f770E03b2',
  //     56: '0xc8FFf74f9AB50B68a727bf2c35afe3A3970910D3',
  //   },
  //   riskPoolAddress: {
  //     1: '0x920D510D5c70C01989b66f4e24687Dddb988DdAe',
  //     4: '0x09Ae34DfB11BAF89b7fe727557E98a3AF94D30e5',
  //     97: '0x901FD16932F009c4c26517f51ff9E10E8c877a79',
  //     56: '0xFC9a02a13B19F65219034AB03ADcD8CAdf275f35',
  //   },
  //   poolName : "Thanatos Pool",
  //   lpSymbol: 'SSIP-USDC LP',
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   tokenPerBlock: '10',
  //   sortOrder: 1,
  //   isFinished: false,
  //   distribution: 0,
  //   poolType: true,
  //   ethPool: false,
  //   lpPool: false,
  //   oldPool: false,
  //   stakedTokenPriceInUsd: 0,
  //   rewardTokenPriceInUsd: 0,
  // },
  {
    poolId: 2,
    stakingToken: tokens.usdt,
    earningToken: tokens.uno,
    contractAddress: {
      1: '0xa476b3F7333796D4565a3D3666D54cF8557F0169',
      5: '0x5aC3f5c5310E067573302eE2977F46000f0fca03',
      97: '0xdb43CC990688c4ef0438e33Da9B0eF888A8A5d36',
      56: '0xBD1105Ce524828f15d7da3CAF098c8E42D0Fbf31',
    },
    rewardAddress: {
      1: '0xa76A4a5c8bd6DC07e165F17B0806553eC02f0852',
      5: '0x7FbC7820779926119bFCf0Fa52f1BA068Cef5011',
      97: '0x4747a35b84A7478Ac3990d640E054dF14C1cCdd2',
      56: '0xc8FFf74f9AB50B68a727bf2c35afe3A3970910D3',
    },
    riskPoolAddress: {
      1: '0x920D510D5c70C01989b66f4e24687Dddb988DdAe',
      5: '0x3E19a8D7e3c36f0122EF6D0b8053e639318c37F6',
      97: '0x2c10C901a81096F78E25Ac68D2c0b849c879420D',
      56: '0xFC9a02a13B19F65219034AB03ADcD8CAdf275f35',
    },
    poolName: 'Hercules Pool',
    lpSymbol: 'SSIP-USDT LP',
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
    distribution: 0,
    poolType: true,
    ethPool: true,
    lpPool: false,
    oldPool: false,
    isSSRP: false,
    isDisabled: false,
    stakedTokenPriceInUsd: 0,
    rewardTokenPriceInUsd: 0,
  },
  {
    poolId: 3,
    stakingToken: tokens.usdc,
    earningToken: tokens.uno,
    contractAddress: {
      1: '0x72D1B61B1723900f64d041a80fe4f114d3F0942a',
      5: '0xC75d6f95CF2E0981de73692E670bB41793dFbA15',
      97: '0xc7f793b735dC6d86e819E3693A93BaB27aDAc820',
      56: '0xBD1105Ce524828f15d7da3CAF098c8E42D0Fbf31',
    },
    rewardAddress: {
      1: '0xAF18Aa409CfD55D45A240d1DAdcCD7CcB6082A76',
      5: '0x9d280E7196FB78F68d3F867e5f505fb0d86d7196',
      97: '0xE512CBa762BF5B5700B9a55115584C2C3505e95D',
      56: '0xc8FFf74f9AB50B68a727bf2c35afe3A3970910D3',
    },
    riskPoolAddress: {
      1: '0xfdfAA453eF3709D2c26EcF43786a14Ab8bF27E36',
      5: '0xe336966836973A26530Cd41E94cfF87Db48B75b4',
      97: '0x03c626BFb9F1BB3C899109375DDAb0a81eAe3756',
      56: '0xFC9a02a13B19F65219034AB03ADcD8CAdf275f35',
    },
    poolName: 'Aphrodite Pool',
    lpSymbol: 'SSIP-USDC LP',
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
    distribution: 0,
    poolType: true,
    ethPool: true,
    lpPool: false,
    oldPool: false,
    isSSRP: false,
    isDisabled: false,
    stakedTokenPriceInUsd: 0,
    rewardTokenPriceInUsd: 0,
  },
  {
    poolId: 4,
    stakingToken: tokens.weth,
    earningToken: tokens.uno,
    contractAddress: {
      1: '0x1342b3dAec4f54F5Af01Aaa34839626f959B362a',
      5: '0x83Ea87C05E240f5BdE442d64a1d2b450fB0a7841',
      97: '0xa8BBCa1E2B7ef69ba71c3827B6D044f241C83202',
      56: '0xBD1105Ce524828f15d7da3CAF098c8E42D0Fbf31',
    },
    rewardAddress: {
      1: '0xE6D0f003C30EC8078DE8C7d104fD52d3E0D78442',
      5: '0x8aBc4c9f0bC5F5c469A380380E87b47E091a32EB',
      97: '0xC72C6627A773fF80b4B2de44eC9f5675b4E5e66b',
      56: '0xc8FFf74f9AB50B68a727bf2c35afe3A3970910D3',
    },
    riskPoolAddress: {
      1: '0x29B4b8674D93b36Bf651d0b86A8e5bE3c378aCF4',
      5: '0x73EdfE2b622A9d7453DbaA2a10766FB98a71Af3A',
      97: '0xBC655a1aB7E9FF9a62b4187e45c48DA5037C264a',
      56: '0xFC9a02a13B19F65219034AB03ADcD8CAdf275f35',
    },
    poolName: 'Selene Pool',
    lpSymbol: 'SSIP-ETH LP',
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
    distribution: 0,
    poolType: true,
    ethPool: true,
    lpPool: false,
    oldPool: false,
    isSSRP: false,
    ethNativePool: true,
    isDisabled: false,
    stakedTokenPriceInUsd: 0,
    rewardTokenPriceInUsd: 0,
  },
  {
    poolId: 5,
    stakingToken: tokens.uno,
    earningToken: tokens.uno,
    contractAddress: {
      1: '0x82E107d2b1Be4347b55FBba4a6fB99669dF3ceb1',
      5: '0xb9D15DD97Bf3De441A774EbE80bDa98F636b06b7',
      97: '0x380A2BdB95C4b3F7b4Ee3c5b6Fdd736fE88B0D2C',
      56: '0xBD1105Ce524828f15d7da3CAF098c8E42D0Fbf31',
    },
    rewardAddress: {
      1: '0xbD417ea106990f013B2392E5A903b3ab23d03d59',
      5: '0xa1b241e5BB40924A39DBf0dcc97e01FF641eC812',
      97: '0x45f22138dfACfFf1e574c0B0f7fe8BBF04eF86F7',
      56: '0xc8FFf74f9AB50B68a727bf2c35afe3A3970910D3',
    },
    riskPoolAddress: {
      1: '0xbd3E70819A8Add92B06d6d92A06DcdA9249DF2a3',
      5: '0x7b2AD0f0be8c7BC560399268dDD26cc8781747a1',
      97: '0x45f22138dfACfFf1e574c0B0f7fe8BBF04eF86F7',
      56: '0xFC9a02a13B19F65219034AB03ADcD8CAdf275f35',
    },
    lpSymbol: 'SSIP-UNO LP',
    poolName: 'Ares Pool',
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
    distribution: 250000,
    poolType: true,
    ethPool: true,
    lpPool: false,
    oldPool: false,
    isSSRP: false,
    isDisabled: false,
    stakedTokenPriceInUsd: 0,
    rewardTokenPriceInUsd: 0,
  },
  {
    poolId: 6,
    stakingToken: tokens.uno,
    earningToken: tokens.uno,
    contractAddress: {
      1: '0x87e1f628225c170a5C0Bf895580686430DEb3322',
      5: '0x57fFdCCDf010262a358DA540a991F5ACCe010CAE',
      97: '0xCeeDd150b9B09a865E21acA29DD5F329BaF9c49d',
      56: '0xBD1105Ce524828f15d7da3CAF098c8E42D0Fbf31',
    },
    rewardAddress: {
      1: '0x7Ba332946e111a87F6c57f72ee361B99d6300Ef2',
      5: '0xd20364228fF7CB0a99353Bf06bA013D21F73Ac43',
      97: '0xCeeDd150b9B09a865E21acA29DD5F329BaF9c49d',
      56: '0xc8FFf74f9AB50B68a727bf2c35afe3A3970910D3',
    },
    riskPoolAddress: {
      1: '0x1eECc8C8298ed9Bd46c147D44E2D7A7BfACE2034',
      5: '0x36Bc245184D2acfEfCA17c582CA356d81Ac7788f',
      97: '0xCeeDd150b9B09a865E21acA29DD5F329BaF9c49d',
      56: '0xFC9a02a13B19F65219034AB03ADcD8CAdf275f35',
    },
    poolName: 'Poseidon Pool',
    lpSymbol: 'SSRP-UNO LP',
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
    distribution: 250000,
    poolType: true,
    isSSRP: true,
    ethPool: true,
    lpPool: false,
    oldPool: false,
    isDisabled: false,
    stakedTokenPriceInUsd: 0,
    rewardTokenPriceInUsd: 0,
  },
  {
    poolId: 7,
    stakingToken: tokens.usdcbsc,
    earningToken: tokens.usdcbsc,
    contractAddress: {
      1: '0x1eECc8C8298ed9Bd46c147D44E2D7A7BfACE2034',
      5: '',
      97: '0xc1686c63D19ff15fB14C96fBd4d433e5ea748e03',
      56: '0xEcE9f1A3e8bb72b94c4eE072D227b9c9ba4cd750',
    },
    rewardAddress: {
      1: '0x0451863C4dc8480D95E9Fb87D73D4f427A671eB6',
      5: '',
      97: '0x71Fdc22F7522D7a07d1509205df0DEec538180f2',
      56: '0xc8FFf74f9AB50B68a727bf2c35afe3A3970910D3',
    },
    riskPoolAddress: {
      1: '0x920D510D5c70C01989b66f4e24687Dddb988DdAe',
      5: '',
      97: '0xCeeDd150b9B09a865E21acA29DD5F329BaF9c49d',
      56: '0xFC9a02a13B19F65219034AB03ADcD8CAdf275f35',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: true,
    distribution: 250000,
    poolType: true,
    poolName: 'Zeus Pool',
    ethPool: false,
    lpPool: false,
    oldPool: true,
    isSSRP: false,
    stakedTokenPriceInUsd: 0,
    rewardTokenPriceInUsd: 0,
  },
  {
    poolId: 8,
    stakingToken: tokens.usdcbsc,
    earningToken: tokens.usdcbsc,
    contractAddress: {
      1: '0x1eECc8C8298ed9Bd46c147D44E2D7A7BfACE2034',
      5: '',
      97: '0xE876915A98d9181B3AE82E92F17bB0cE318E5750',
      56: '0x0b5C802ecA88161B5daed08e488C83d819a0cD02',
    },
    rewardAddress: {
      1: '0x0451863C4dc8480D95E9Fb87D73D4f427A671eB6',
      5: '',
      97: '0x71Fdc22F7522D7a07d1509205df0DEec538180f2',
      56: '0xc8FFf74f9AB50B68a727bf2c35afe3A3970910D3',
    },
    riskPoolAddress: {
      1: '0x920D510D5c70C01989b66f4e24687Dddb988DdAe',
      5: '',
      97: '0xCeeDd150b9B09a865E21acA29DD5F329BaF9c49d',
      56: '0xFC9a02a13B19F65219034AB03ADcD8CAdf275f35',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: true,
    distribution: 900000,
    poolType: true,
    poolName: 'Athena Pool',
    ethPool: false,
    lpPool: false,
    oldPool: true,
    isSSRP: false,
    stakedTokenPriceInUsd: 0,
    rewardTokenPriceInUsd: 0,
  },
  {
    poolId: 9,
    stakingToken: tokens.usdcbsc,
    earningToken: tokens.usdcbsc,
    contractAddress: {
      1: '0x1eECc8C8298ed9Bd46c147D44E2D7A7BfACE2034',
      5: '',
      97: '0x9EF59B956aF99Ef460B009a2EFA7c38973540a99',
      56: '0x2cd32dF1C436f8dE6e09d1A9851945c56bcEd32a',
    },
    rewardAddress: {
      1: '0x0451863C4dc8480D95E9Fb87D73D4f427A671eB6',
      5: '',
      97: '0x71Fdc22F7522D7a07d1509205df0DEec538180f2',
      56: '0xc8FFf74f9AB50B68a727bf2c35afe3A3970910D3',
    },
    riskPoolAddress: {
      1: '0x920D510D5c70C01989b66f4e24687Dddb988DdAe',
      5: '',
      97: '0xCeeDd150b9B09a865E21acA29DD5F329BaF9c49d',
      56: '0xFC9a02a13B19F65219034AB03ADcD8CAdf275f35',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: true,
    distribution: 1320000,
    poolType: true,
    poolName: 'Artemis Pool',
    ethPool: false,
    lpPool: false,
    oldPool: true,
    isSSRP: false,
    stakedTokenPriceInUsd: 0,
    rewardTokenPriceInUsd: 0,
  },
]
export const premiumPool: PoolConfig = {
    poolId: null,
    stakingToken: tokens.usdc,
    earningToken: tokens.usdc,
    contractAddress: {
      1: '0xdB4B701f1a4653BFD5F0f4EFF1913aEAF5E21E68',
      5: '',
      97: '0x8a88F5A0EBC56E7eE94a0d8045CE4D873BBa51f0',
      56: '0xd85D5144718982bdD442E67d9810151a6AdF31c2',
    },
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
}

interface Protocol {
  protocolId: number;
  name?: string;
}

export const protocols: Protocol[] = [
  {
    protocolId: 0
  },
  {
    protocolId: 1
  },
  {
    protocolId: 2
  },
  {
    protocolId: 3
  },
  {
    protocolId: 4
  }
]

// export const poolNames = {
//   "0x1eECc8C8298ed9Bd46c147D44E2D7A7BfACE2034": "Poseidon Pool",
//   // "0xB4Cc174B009d8780Df702afB9AaCACD970C4463f" : "Poseidon Pool",
//   "0xEcE9f1A3e8bb72b94c4eE072D227b9c9ba4cd750" : "Zeus Pool",
//   "0x0b5C802ecA88161B5daed08e488C83d819a0cD02": "Athena Pool",
//   "0x2cd32dF1C436f8dE6e09d1A9851945c56bcEd32a": "Artemis Pool",
//   "0xd85D5144718982bdD442E67d9810151a6AdF31c2": "Premium Pool"
// }
// export const PoolIds = {
//   "Poseidon Pool": 0,
//   "Zeus Pool": 1,
//   "Athena Pool": 2,
//   "Artemis Pool": 3,
// }
export default pools
