import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AlertColor, Box, Card } from '@mui/material'
import AddressListResults from './components/AddressListResults'
import AddAddress from './components/AddAddress'
import { useAuthState } from 'state/auth/hooks'
import { useGetWhiteList } from 'hooks/useGetWhiteList'
import CustomizedSnackbars from 'components/SnackBar'
import { addWhitelist, deleteWhitelist } from 'utils/apiHelpers'
import ConfirmDlg from 'components/modals/ConfirmModal'
import { useGetChainListAll } from 'hooks/useGetChainData'
import EditBlacklistDlg from './components/EditWhitelistDlg'
import { useGetProtocolListAll } from 'hooks/useGetProtocolList'
import { useGetTokenListAll } from 'hooks/useGetTokenData'

interface ChainType {
  _id?: string
  chain_id?: string
  chain_name?: string
  chain_logo?: string
}

const Address = () => {
  const { userName } = useAuthState()
  const navigate = useNavigate()
  const [updated, setUpdated] = useState(false)
  const [newAddr, setNewAddr] = useState('')
  const [serverity, setServerity] = useState<AlertColor>('success')
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [message, setAlertMessage] = useState('')
  const [selectedChains, setChains] = useState<ChainType>({})

  const [selectedProtocol, setSelectedProtocol] = useState<any>()
  const [selectedTokens, setSelectedTokens] = useState<any[]>()

  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(0)

  const [confirmDlgOpen, setConfirmDlgOpen] = useState(false)
  const [deleteId, setDeleteId] = useState<any>(null)

  const [openEditDlg, setOpenEditDlg] = useState(false)
  const [editItem, setEditItem] = useState(null)

  const { wladdresses, totalItems } = useGetWhiteList(page, limit, updated)
  const { chains } = useGetChainListAll(updated)
  const { protocols } = useGetProtocolListAll()
  const { tokens } = useGetTokenListAll()

  useEffect(() => {
    if (userName === '') {
      navigate('/')
    }
  }, [])

  const handleSetNewAddress = (e: any) => {
    setNewAddr(e.target.value)
  }

  const AddWhitelist = () => {
    if (!newAddr.includes('0x') || newAddr.length !== 42) {
      handleOpenSnackBar('warning', 'Invalid address.')
      return
    }
    if (!selectedChains?.chain_id) {
      handleOpenSnackBar('warning', 'Invalid chain id.')
      return
    }
    if (!selectedProtocol) {      
      handleOpenSnackBar('warning', 'Invalid protocol.')
      return
    }
    
    addWhitelist({
      address: newAddr,
      chain_id: selectedChains.chain_id,
      protocol_id: selectedProtocol.protocol_id,
      tokens_covered: selectedTokens ? selectedTokens.map((_) => _.token_id) : []
    })
      .then((res: any) => {
        if (res.success) {
          setNewAddr('')
          setChains({})
          handleOpenSnackBar('success', res.message)
        } else {
          setNewAddr('')
          handleOpenSnackBar('error', res.message)
        }
      })
      .catch((err: any) => {
        setNewAddr('')
        handleOpenSnackBar('error', err.message)
      })
  }

  const hanleDeleteBlacklist = (id: string, address: string, pId: string) => {
    setDeleteId({id, address, pId})
    setConfirmDlgOpen(true)
  }
  const handleDeleteConfirm = () => {
    if (!deleteId) {
      return
    }
    deleteWhitelist(deleteId.id, deleteId.address, deleteId.pId)
      .then((res: any) => {
        if (res.success) {
          handleOpenSnackBar('success', res.message)
          setDeleteId('')
          setConfirmDlgOpen(false)
        } else {
          handleOpenSnackBar('error', res.message)
          setConfirmDlgOpen(false)
        }
      })
      .catch((err: any) => {
        handleOpenSnackBar('error', err.message)
      })
  }

  const hanleEditWhitelist = (item: any) => {
    setEditItem(item)
    setOpenEditDlg(true)
  }

  const handleOpenSnackBar = (type: AlertColor, msg: string) => {
    setServerity(type)
    setAlertMessage(msg)
    setSnackBarOpen(true)
    setUpdated(!updated)
  }

  const handleSnackBarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackBarOpen(false)
  }

  const handleSelectChains = (e: any, v: ChainType) => {
    setChains(v)
  }

  const handleSelectProtocol = (e: any, v: any) => {
    setSelectedProtocol(v)
  }

  const handleSelectToken = (e: any, v: any) => {
    setSelectedTokens(v)
  }

  return (
    <>
      <Box
        component="main"
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          p: '20px',
        }}
      >
        <Card sx={{ marginTop: 10, overflowX: 'auto', minWidth: '280px', width: '100%', maxWidth: '800px' }}>
          <AddAddress
            value={newAddr}
            chainData = {chains ?? []}
            protocols = {protocols ?? []}
            tokens = {tokens ?? []}
            handleAddBlacklist={AddWhitelist}
            handleSetNewAddress={handleSetNewAddress}
            handleSelectChains={handleSelectChains}
            handleSelectProtocol={handleSelectProtocol}
            handleSelectToken={handleSelectToken}
          />
          <AddressListResults
            addresses={wladdresses}
            limit={limit}
            page={page}
            totalCounts={totalItems}
            hanleEditBlacklist={hanleEditWhitelist}
            setPage={(p: number) => setPage(p)}
            setLimit={(l: number) => setLimit(l)}
            hanleDeleteBlacklist={hanleDeleteBlacklist}
          />
        </Card>
        <EditBlacklistDlg
          open={openEditDlg}
          chainData={chains ?? []}
          item={editItem}
          handleClose={() => setOpenEditDlg(false)}
          handleOpenSnackBar={handleOpenSnackBar}
        />
        <ConfirmDlg
          open={confirmDlgOpen}
          handleClose={() => setConfirmDlgOpen(false)}
          handleConfirm={handleDeleteConfirm}
        />
        <CustomizedSnackbars
          serverity={serverity}
          snackBarOpen={snackBarOpen}
          message={message}
          handleSnackBarClose={handleSnackBarClose}
        />
      </Box>
    </>
  )
}

export default Address
