import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps , AlertColor} from '@mui/material/Alert';

interface SnackbarProps {
  serverity: AlertColor;
  message: string;
  snackBarOpen: boolean;
  handleSnackBarClose: (e?: React.SyntheticEvent | Event, r?: string) => void;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomizedSnackbars: React.FC<SnackbarProps> = ({ serverity, message, snackBarOpen, handleSnackBarClose }) => {

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar open={snackBarOpen} autoHideDuration={2000} onClose={handleSnackBarClose}>
        <Alert onClose={handleSnackBarClose} severity={serverity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}

export default CustomizedSnackbars;
