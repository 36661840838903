import { useCallback, useState } from 'react';
import { useTokenContract } from './useContract';
import { useWeb3React } from '@web3-react/core';
import { getTokenAddress, getSalesPolicyAddress } from 'utils/addressHelpers';

const useGetAllowanceOfToken = (address = null, tokenSymbol = 'usdc') => {
  const { account } = useWeb3React();
  const [usdcAllowance, setAllowance] = useState(false);
  const tokenAddress = getTokenAddress(tokenSymbol);
  const tokenContract = useTokenContract(tokenAddress);
  const approveAddr = address ?? getSalesPolicyAddress();

  const handleAllowance = useCallback(() => {
    const get = async () => {
      const res = await tokenContract.allowance(account, approveAddr);
      setAllowance(res.gt(0));
    };

    if (approveAddr && tokenContract && account) {
      get();
    }
  }, [tokenContract, approveAddr, account]);

  return { usdcAllowance, handleAllowance };
};

export default useGetAllowanceOfToken;
