export const BASE_API_URL =
  process.env.REACT_APP_MODE === 'testnet' ? 'https://staging-admin.unore.io/api/v1/' : 'https://admin.unore.io/api/v1/'

export const COINGECKO_BASE_URL = 'https://api.coingecko.com/api/v3/'

export enum ChainId {
  MAINNET = 1,
  TESTNET = 4,
  KAVA = 2221,
}

const latestId = '0x0000000000000000000000000000000000010000'
