import React from 'react'
import { Box, Button, TextField, Autocomplete } from '@mui/material'
interface ChainType {
  _id?: string
  chain_id?: string
  chain_name?: string
  chain_logo?: string
}

const ChainSelectBox: React.FC<{ data: any, handleSelectChains?: (e: any, v: any) => void }> = ({ data, handleSelectChains }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
      {/* <Typography gutterBottom sx={{ minWidth: 50 }}>
        Chain :
      </Typography> */}
      <Autocomplete
        limitTags={2}
        id="multiple-limit-tags"
        options={data}
        getOptionLabel={(option) => option.chain_name}
        defaultValue={data[0]}
        renderInput={(params) => <TextField {...params} label="Supported chain" placeholder="chains" />}
        fullWidth
        sx={{ minWidth: 250 }}
        size = "small"
        onChange={handleSelectChains}
      />
    </Box>
  )
}

const AddAddress: React.FC<{
  value: string;
  chainData?: any;
  handleAddBlacklist: () => void;
  handleSetNewAddress: (e: any) => void;
  handleSelectChains: (e: any, v: ChainType) => void
}> = ({ value, chainData, handleAddBlacklist, handleSetNewAddress, handleSelectChains }) => {

  return (
    <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
      <Box sx={{ py: 2, width: '100%', }}>
        <TextField
          fullWidth
          size="small"
          placeholder="Enter blacklisted address"
          variant="outlined"
          onChange={handleSetNewAddress}
          value = {value}
        />
       <ChainSelectBox data={chainData} handleSelectChains={handleSelectChains}/>
      </Box>
      <Box sx={{marginLeft: 5}}>
        <Button color="success" disabled={false} size="small" variant="contained" onClick={handleAddBlacklist}>
          Add
        </Button>
      </Box>
    </Box>
  )
}

export default AddAddress
