import { useMemo } from 'react';
import { ethers } from 'ethers';
import { useWeb3React } from '@web3-react/core';
import { Biconomy } from '@biconomy/mexa';
import { simpleRpcProvider } from '../utils/providers';
import salesPolicyAbi from 'config/abi/salesPolicy.json';
import { getSalesPolicyAddress } from '../utils/addressHelpers';

export const useInitBiconomy = () => {
  const { account } = useWeb3React();

  return useMemo(() => {
    const BICONOMY_API_KEY = process.env.REACT_APP_BICONOMY_API_KEY    
    if (typeof window.ethereum !== 'undefined' && window.ethereum?.isMetaMask && BICONOMY_API_KEY) {
      const biconomy = new Biconomy(simpleRpcProvider, {
        walletProvider: window.ethereum,
        apiKey: BICONOMY_API_KEY,
        debug: false,
      })
      const contractInterface = new ethers.utils.Interface(salesPolicyAbi)

      const salesPolicyContract = new ethers.Contract(
        getSalesPolicyAddress(),
        contractInterface,
        biconomy.getSignerByAddress(account)
      )

      return {
        salesPolicyContract,
      }
    } 
    return {
      salesPolicyContract: undefined,
    }
  }, [account])
}
