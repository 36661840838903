import { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import BigNumber from 'bignumber.js';
import { useWeb3React } from '@web3-react/core';
import { BIG_ZERO } from '../utils/bigNumber';
import ethSimpleProvider from '../utils/providers';
import { getBep20Contract } from '../utils/contractHelpers';
import { simpleRpcProvider } from '../utils/providers';
import { getBalanceAmount } from '../utils/formatBalance';

export const FetchStatus = {
  NOT_FETCHED: 'not-fetched',
  SUCCESS: 'success',
  FAILED: 'failed',
};

const useTokenBalance = (tokenAddress) => {
  const { NOT_FETCHED, SUCCESS, FAILED } = FetchStatus;

  const [balanceState, setBalanceState] = useState({
    balance: BIG_ZERO,
    fetchStatus: NOT_FETCHED,
  });
  const { account } = useWeb3React();

  useEffect(() => {
    const fetchBalance = async () => {
      const contract = await getBep20Contract(tokenAddress);
      try {
        const res = await contract.balanceOf(account);
        setBalanceState({ balance: new BigNumber(res.toString()), fetchStatus: SUCCESS });
      } catch (e) {
        console.error(e);
        setBalanceState((prev) => ({
          ...prev,
          fetchStatus: FAILED,
        }));
      }
    };

    if (account) {
      fetchBalance();
    }
  }, [account, tokenAddress, SUCCESS, FAILED]);

  return balanceState;
};

export const useGetEthBalance = () => {
  const [fetchStatus, setFetchStatus] = useState(FetchStatus.NOT_FETCHED);
  const [balance, setBalance] = useState(ethers.BigNumber.from(0));
  const { account } = useWeb3React();

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const walletBalance = await ethSimpleProvider.getBalance(account);
        setBalance(walletBalance);
        setFetchStatus(FetchStatus.SUCCESS);
      } catch {
        setFetchStatus(FetchStatus.FAILED);
      }
    };
    if (account) {
      fetchBalance();
    }
  }, [account, setBalance, setFetchStatus]);

  return { balance, fetchStatus };
};

export const useGetNativeCoinBalance = () => {
  const [balances, setBalances] = useState(null);
  const { account, chainId } = useWeb3React();

  useEffect(() => {
    const get = async () => {
      const amount = await simpleRpcProvider.getBalance(account);
      setBalances(getBalanceAmount(new BigNumber(amount.toString()), 18));
    }
    if (account) {
      get();
    }
  }, [account, chainId]);

  return balances;
}

export default useTokenBalance;
