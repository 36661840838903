import React, { useState, useEffect } from 'react'
import { Box } from '@mui/system'
import { useNavigate } from 'react-router-dom'
import { AlertColor, Card } from '@mui/material'
import PageHeader from './components/PageHeader'
import ChainsList from './components/ChainsList'
import { useGetChainListWithPagination, useGetChainListAll } from 'hooks/useGetChainData'
import AddChainModal from './components/modals/AddChainDlg'
import CustomizedSnackbars from 'components/SnackBar'
import ConfirmDlg from 'components/modals/ConfirmModal'
import { deleteChain } from 'utils/apiHelpers'
import EditChainDlg from './components/modals/EditChainDlg'
import { useAuthState } from 'state/auth/hooks'

const Chains = () => {
  const [openAddDlg, setOpenAddDlg] = useState<boolean>(false)
  const [openEditDlg, setOpenEditDlg] = useState<boolean>(false)
  const [editItem, setEditItem] = useState({})
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(0)
  const [serverity, setServerity] = useState<AlertColor>('success')
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [message, setAlertMessage] = useState('')
  const [updated, setUpdated] = useState(false)
  const [deleteId, setDeleteId] = useState('')
  const [confirmDlgOpen, setConfirmDlgOpen] = useState(false)

  const { totalItems, chains } = useGetChainListWithPagination(page, limit, updated)
  const chainData = useGetChainListAll(updated)

  const { userName } = useAuthState()
  const navigate = useNavigate()
  
  useEffect(() => {
    if (userName === '') {
      navigate('/');
    }
  }, [])

  const handleOpenAddChainDlg = () => {
    setOpenAddDlg(true)
  }

  const handleSetPage = (value: number) => {
    setPage(value)
  }

  const handleSetLimit = (value: number) => {
    setLimit(value)
  }

  const handleEditChain = (item: any) => {
    setEditItem(item)
    setOpenEditDlg(true)
  }

  const handleDeleteItem = (id: string) => {
    setDeleteId(id)
    setConfirmDlgOpen(true)
  }

  const handleOpenSnackBar = (type: AlertColor, msg: string) => {
    setServerity(type)
    setAlertMessage(msg)
    setSnackBarOpen(true)
    setUpdated(!updated)
  }
  const handleSnackBarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackBarOpen(false)
  }

  const handleDeleteConfirm = () => {
    if (deleteId === '') {
      return
    }
    deleteChain(deleteId)
      .then((res: any) => {
        if (res.success) {
          setServerity('success')
          setAlertMessage(res.message)
          setSnackBarOpen(true)
          setUpdated(!updated)
          setConfirmDlgOpen(false)
          setDeleteId('')
        }
      })
      .catch((err: any) => {
        setServerity('error')
        setAlertMessage(err.message)
        setSnackBarOpen(true)
      })
  }

  return (
    <>
      <Box
        component="main"
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          p: '20px',
        }}
      >
        <Card sx={{ marginTop: 10, overflowX: 'auto', minWidth: '280px', width: '100%', maxWidth: '800px' }}>
          <PageHeader handleOpenAddChainDlg={handleOpenAddChainDlg} />
          <ChainsList
            chains={chains ?? []}
            page={page}
            limit={limit}
            totalCounts={totalItems ?? 0}
            setPage={handleSetPage}
            setLimit={handleSetLimit}
            handleDeleteItem={handleDeleteItem}
            handleEditChain={handleEditChain}
          />
        </Card>
      </Box>

      <AddChainModal
        open={openAddDlg}
        chainData={chainData?.totalItems}
        handleClose={() => setOpenAddDlg(false)}
        handleOpenSnackBar={handleOpenSnackBar}
      />

      <EditChainDlg
        open={openEditDlg}
        item={editItem}
        handleClose={() => setOpenEditDlg(false)}
        handleOpenSnackBar={handleOpenSnackBar}
      />

      <CustomizedSnackbars
        serverity={serverity}
        snackBarOpen={snackBarOpen}
        message={message}
        handleSnackBarClose={handleSnackBarClose}
      />

      <ConfirmDlg
        open={confirmDlgOpen}
        handleClose={() => setConfirmDlgOpen(false)}
        handleConfirm={handleDeleteConfirm}
      />
    </>
  )
}

export default Chains
