import React, { useEffect, useState } from 'react'
import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Autocomplete,
  TextField,
  AlertColor,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/material/styles'
import { updateWhitelist } from 'utils/apiHelpers'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

interface ChainType {
  _id?: number
  chain_id?: string
  chain_name?: string
  chain_logo?: string
  createdAt?: string
  updatedAt?: string
  __v?: number
}
interface ModalProps {
  open: boolean
  chainData: ChainType[]
  item: any
  handleClose: () => void
  handleOpenSnackBar: (type: AlertColor, msg: string) => void
}

const InputItem: React.FC<{
  label: string
  placeholder?: string
  value?: string | number
  error?: boolean
  disabled?: boolean
  handleChange?: (e: any) => void
}> = ({ label, placeholder, value, error, disabled, handleChange }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
      <Typography gutterBottom sx={{ minWidth: 75 }}>{`${label} :`}</Typography>
      <TextField
        value={value}
        error={error}
        disabled={disabled}
        fullWidth
        size="small"
        placeholder={placeholder}
        variant="outlined"
        sx={{ marginLeft: 5, minWidth: 250 }}
        onChange={handleChange}
      />
    </Box>
  )
}


const ChainSelectBox: React.FC<{ data: any, value: ChainType, handleSelectChains?: (e: any, v: any) => void }> = ({ data, value, handleSelectChains }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
      <Typography gutterBottom sx={{ minWidth: 70 }}>
        Chain :
      </Typography>
      <Autocomplete
        limitTags={2}
        id="multiple-limit-tags"
        options={data}
        getOptionLabel={(option) => option.chain_name}
        defaultValue={data[0]}
        value={value}
        renderInput={(params) => <TextField {...params} label="Supported chain" placeholder="chains" />}
        fullWidth
        sx={{ marginLeft: 5, minWidth: 250 }}
        size = "small"
        onChange={handleSelectChains}
      />
    </Box>
  )
}

const StatusSelectBox: React.FC<{ handleSelectStatus?: (e: any, v: any) => void, value: number }> = ({ handleSelectStatus, value }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
      <Typography gutterBottom sx={{ minWidth: 70 }}>
        Status :
      </Typography>
      <Autocomplete
        fullWidth
        disablePortal
        value={value === 1 ? 'Active' : 'Inactive'}
        // defaultValue={value === 1 ? 'Active' : 'Inactive'}
        size = "small"
        id="select-box-demo"
        options={['Inactive', 'Active']}
        sx={{ marginLeft: 5, minWidth: 250 }}
        renderInput={(params) => <TextField {...params} label="Protocol Status" />}
        onChange={handleSelectStatus}
      />
    </Box>
  )
}

const EditWhitelistDlg: React.FC<ModalProps> = ({ open, chainData, item, handleClose, handleOpenSnackBar }) => {
  const [address, setTokenAddress] = useState('')
  const [chains, setChains] = useState<ChainType>()
  const [status, setStatus] = useState<number>(1)

  useEffect(() => {
    if (item) {
      setTokenAddress(item.contract_addr_list && item.contract_addr_list?.length ? item.contract_addr_list[0].address : '')
      setStatus(item.contract_addr_list[0].status)
      if (chainData) {
        const currentItem = chainData.find((_) => Number(_.chain_id) === Number(item.chain_id))
        setChains(currentItem)
      }
    }
  }, [item])

  const handleSelectStatus = (e: any, v: string) => {
    setStatus(v === 'Active' ? 1 : 0)
  }

  const handleUpdateBlacklist = () => {
    if (!validation()) {
      return
    }
    const body = {
      _id: item._id,
      address,
      chain_id: chains.chain_id,
      status,
      protocol_id: item.contract_addr_list[0].protocol_id
    }
    updateWhitelist(body)
      .then((res: any) => {
        if (res.success) {
          handleOpenSnackBar('success', res.message)
          handleClose()
        } else {
          handleOpenSnackBar('error', res.message)
          handleClose()
        }
      })
      .catch((err: any) => {
        handleOpenSnackBar('error', err.message)
        handleClose()
      })
  }

  const validation = () => {
    if (!address.includes('0x') || address.length !== 42) {
      handleOpenSnackBar('warning', 'Invalid address.')
      return false
    }
    if (!chains) {
      handleOpenSnackBar('warning', 'Chain type is required.')
      return false
    }
    return true
  }
  const handleSelectChains = (e: any, v: ChainType) => {
    setChains(v)
  }

  return (
    <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle
        sx={{ m: 0, p: 2, textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <div />
        <div style={{ fontSize: '20px'}}>Update a whitelist status</div>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <InputItem
          label="Address"
          placeholder="Token address"
          value={address}
          handleChange={(e) => setTokenAddress(e.target.value)}
        />
        <ChainSelectBox data={chainData} value={chains} handleSelectChains={handleSelectChains} />
        <StatusSelectBox handleSelectStatus={handleSelectStatus} value = {status} />
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Box sx={{ py: 2 }}>
          <Button autoFocus color="primary" variant="contained" onClick={handleUpdateBlacklist}>
            Update a whitelist
          </Button>
        </Box>
      </DialogActions>
    </BootstrapDialog>
  )
}

export default EditWhitelistDlg
