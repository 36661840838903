import { useEffect, useState, useMemo } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  Avatar,
  Box,
  Card,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Link,
  Radio,
  RadioGroup,
  FormControlLabel,
  TableSortLabel,
  Grid,
} from '@mui/material'
import { getInitials } from 'utils/get-initials'

interface ProtocolListProps {
  limit: number
  page: number
  protocols?: any
  totalItems?: number
  handleRemove: (id: string) => void
  handleEdit: (item: any) => void
  handleLimit: (value: number) => void
  handlePage: (value: number) => void
  handleBuyPolicy: (item: any) => void
  handleChangeStatus: (value: number[]) => void
}

const orderDirections: { [key: string]: 'asc' | 'desc' } = {
  product_name: 'asc',
  product_link: 'asc',
  premium_factor: 'asc',
}

const ProtocolsListResults: React.FC<ProtocolListProps> = ({
  protocols,
  limit,
  page,
  totalItems,
  handleRemove,
  handleEdit,
  handleLimit,
  handlePage,
  handleBuyPolicy,
  handleChangeStatus,
}) => {
  const [data, setData] = useState([])
  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('asc')
  const [orderDirection1, setOrderDirection1] = useState<'asc' | 'desc'>('asc')
  const [orderDirection2, setOrderDirection2] = useState<'asc' | 'desc'>('asc')

  useEffect(() => {
    setData(protocols)
  }, [protocols])

  const handleLimitChange = (event: any) => {
    handleLimit(Number(event.target.value))
  }

  const handlePageChange = (event: any, newPage: number) => {
    handlePage(Number(newPage))
  }

  const sortArray = (arr, orderBy, key) => {
    switch (orderBy) {
      case 'asc':
      default:
        return arr.sort((a, b) => (a[`${key}`] > b[`${key}`] ? 1 : b[`${key}`] > a[`${key}`] ? -1 : 0))
      case 'desc':
        return arr.sort((a, b) => (a[`${key}`] < b[`${key}`] ? 1 : b[`${key}`] < a[`${key}`] ? -1 : 0))
    }
  }

  const handleSortRequest = (key) => {
    let temp = orderDirection
    if (key === 'product_link') {
      temp = orderDirection1
    }
    if (key === 'premium_factor') {
      temp = orderDirection2
    }
    setData(sortArray(data, temp, key))
    if (key === 'product_name') {
      setOrderDirection(orderDirection === 'asc' ? 'desc' : 'asc')
    }
    if (key === 'product_link') {
      setOrderDirection1(orderDirection1 === 'asc' ? 'desc' : 'asc')
    }
    if (key === 'premium_factor') {
      setOrderDirection2(orderDirection2 === 'asc' ? 'desc' : 'asc')
    }
  }

  const [value, setValue] = useState('active')

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = (event.target as HTMLInputElement).value
    handleChangeStatus(val === 'active' ? [1] : [0])
    setValue(val)
  }

  return (
    <Card sx={{ overflowX: 'auto' }}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table sx={{ overflowX: 'auto' }}>
            <TableHead>
              <TableRow>
                <TableCell onClick={() => handleSortRequest('product_name')}>
                  <TableSortLabel active={true} direction={orderDirection}>
                    NAME
                  </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => handleSortRequest('product_link')}>
                  <TableSortLabel active={true} direction={orderDirection1}>
                    PROTOCOL LINK
                  </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => handleSortRequest('premium_factor')}>
                  <TableSortLabel active={true} direction={orderDirection2}>
                    PREMIUM FACTOR
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <Grid container>
                    <Grid item xs={4}>
                      STATUS
                    </Grid>
                    <Grid item xs={6}>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={value}
                        onChange={handleChange}
                      >
                        <FormControlLabel value="active" control={<Radio size="small" />} label="active" />
                        <FormControlLabel value="inactive" control={<Radio size="small" />} label="inactive" />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </TableCell>
                {/* <TableCell>DELETE</TableCell> */}
                <TableCell>EDIT</TableCell>
                <TableCell>BOOK POLICY</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.slice(0, limit).map((customer: any) => (
                <TableRow hover key={customer._id}>
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                      }}
                    >
                      <Avatar
                        src={
                          !customer.product_logo.startsWith('https://')
                            ? `https://raw.githubusercontent.com/Uno-Re/cover-products-info/main/${customer.product_logo}`
                            : customer.product_logo
                        }
                        sx={{ mr: 2 }}
                      >
                        {getInitials(customer.product_name)}
                      </Avatar>
                      <Typography color="textPrimary" variant="body1">
                        {`${customer.product_name}`}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Link rel="noopener noreferrer" href={customer.product_link} target="_blank">
                      {customer.product_link}
                    </Link>
                  </TableCell>
                  <TableCell>{customer.premium_factor}</TableCell>
                  <TableCell>
                    <Box>{customer.status === 1 ? 'Active' : 'Inactive'}</Box>
                  </TableCell>
                  {/* <TableCell>
                    <Box>
                      <Button color="error" disabled={false} size="small" variant="contained" onClick={() => handleRemove(customer._id)}>
                        Delete
                      </Button>
                    </Box>
                  </TableCell> */}
                  <TableCell>
                    <Box>
                      <Button
                        color="success"
                        disabled={false}
                        size="small"
                        variant="contained"
                        onClick={() => handleEdit(customer)}
                      >
                        Edit
                      </Button>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box>
                      <Button
                        color="info"
                        disabled={false}
                        size="small"
                        variant="contained"
                        onClick={() => handleBuyPolicy(customer)}
                      >
                        Book Policy
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={totalItems ?? 0}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  )
}

export default ProtocolsListResults
