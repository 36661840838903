import React, { useState } from 'react'
import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Autocomplete,
  AlertColor,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/material/styles'
import { addPType } from 'utils/apiHelpers'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

interface ModalProps {
  open: boolean
  handleClose: () => void
  handleOpenSnackBar: (type: AlertColor, msg: string) => void
}

const InputItem: React.FC<{
  label: string
  placeholder?: string
  value?: string | number
  error?: boolean
  disabled?: boolean
  handleChange?: (e: any) => void
}> = ({ label, placeholder, value, error, disabled, handleChange }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
      <Typography gutterBottom sx={{ minWidth: 70 }}>{`${label} :`}</Typography>
      <TextField
        value={value}
        error={error}
        disabled={disabled}
        fullWidth
        size="small"
        placeholder={placeholder}
        variant="outlined"
        sx={{ marginLeft: 5, minWidth: 250 }}
        onChange={handleChange}
      />
    </Box>
  )
}
const TypeSelectBox: React.FC<{ handleSelectTypes?: (e: any, v: any) => void }> = ({ handleSelectTypes }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
      <Typography gutterBottom sx={{ minWidth: 70 }}>
        Type* :
      </Typography>
      <Autocomplete
        fullWidth
        disablePortal
        size = "small"
        id="select-box-demo"
        options={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']}
        sx={{ marginLeft: 5, minWidth: 250 }}
        renderInput={(params) => <TextField {...params} label="Product Type" />}
        onChange={handleSelectTypes}
      />
    </Box>
  )
}
const AddPTypeDlg: React.FC<ModalProps> = ({ open, handleClose, handleOpenSnackBar }) => {
  const [pTypeName, setPTypeName] = useState('')
  const [terms, setTerms] = useState('')
  const [protocolType, setProtocolType] = useState<number>(0)

  const handleSelectTypes = (e: any, v: string) => {
    setProtocolType(Number(v))
  }

  const handleAddPType = () => {
    if (!validation()) {
      return
    }
    const body = {
      product_type: protocolType,
      product_name: pTypeName,
      product_type_terms: terms,
    }
    addPType(body)
      .then((res: any) => {
        if (res.success) {
          handleOpenSnackBar('success', res.message)
          handleClose()
        } else {
          handleOpenSnackBar('error', res.message)
          handleClose()
        }
      })
      .catch((err: any) => {
        handleOpenSnackBar('error', err.message)
        handleClose()
      })
  }

  const validation = () => {
    if (pTypeName === '') {
      handleOpenSnackBar('warning', 'Product type name is required.')
      return false
    }
    if (terms === '') {
      handleOpenSnackBar('warning', 'Product type terms is required.')
      return false
    }
    
    if (protocolType === 0) {
      handleOpenSnackBar('warning', 'Product type is required.')
      return false
    }
    return true
  }

  return (
    <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle
        sx={{ m: 0, p: 2, textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <div />
        <Typography variant="h5">Add a new product type</Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <InputItem
          label="Name*"
          placeholder="Product type name"
          error={pTypeName === ''}
          handleChange={(e) => setPTypeName(e.target.value)}
        />
        <InputItem
          label="Terms*"
          placeholder="Product type terms"
          error={terms === ''}
          handleChange={(e) => setTerms(e.target.value)}
        />
        <TypeSelectBox handleSelectTypes={handleSelectTypes} />
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Box sx={{ py: 2 }}>
          <Button autoFocus color="primary" variant="contained" onClick={handleAddPType}>
            Add Product Type
          </Button>
        </Box>
      </DialogActions>
    </BootstrapDialog>
  )
}

export default AddPTypeDlg
