import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch, AppDispatch, AppState } from 'state';
import { fetchPoolsPublicDataAsync, fetchPremiumPoolsPublicDataAsync } from '.';

export function useGetPoolsData(): AppState['pools'] {
  return useSelector<AppState, AppState['pools']>((state) => state.pools);
}

export const useGetPoolsPublicData = () => {
  const dispatch: AppDispatch = useAppDispatch()
  // const { slowRefresh } = useRefresh()

  useEffect(() => {
    const fetchPoolsPublicData = async () => {
      dispatch(fetchPoolsPublicDataAsync())
      dispatch(fetchPremiumPoolsPublicDataAsync())
    }
    fetchPoolsPublicData()
  }, [dispatch])
}