import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { BscConnector } from '@binance-chain/bsc-connector'
import { ethers } from 'ethers'
import { ChainId } from 'config'

const POLLING_INTERVAL = 12000
export const ALL_SUPPORTED_CHAIN_IDS = [1, 4, 2221];

const injected = new InjectedConnector({ supportedChainIds: ALL_SUPPORTED_CHAIN_IDS })

export enum ConnectorNames {
  Injected = "injected",
  WalletConnect = "walletconnect",
  BSC = "bsc",
}
export const connectorLocalStorageKey = "connectorIdUnore";

export const NETWORK_URLS: any = {
  [ChainId.MAINNET]: process.env.REACT_APP_NODE_1,
};

export const walletconnect = new WalletConnectConnector({
  supportedChainIds: ALL_SUPPORTED_CHAIN_IDS,
  rpc: NETWORK_URLS,
  qrcode: true,
});

const bscConnector = new BscConnector({ supportedChainIds: ALL_SUPPORTED_CHAIN_IDS })

export const connectorsByName: { [connectorName: string]: any } = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.WalletConnect]: walletconnect,
  [ConnectorNames.BSC]: bscConnector,
}

export const getLibrary = (provider: any) => {
  const library = new ethers.providers.Web3Provider(provider)
  library.pollingInterval = POLLING_INTERVAL
  return library
}
