export interface Address {
  1?: string
  5?: string
  97?: string
  56?: string
}

export interface Token {
  symbol: string;
  address?: Address;
  decimals?: number;
  projectLink?: string;
  busdPrice?: string;
}

export enum PoolCategory {
  'COMMUNITY' = 'Community',
  'CORE' = 'Core',
  'AUTO' = 'Auto',
}


// export enum PoolCategory {
//   'COMMUNITY' = 'Community',
//   'CORE' = 'Core',
//   'AUTO' = 'Auto',
// }

export interface PoolConfig {
  poolId: number | null;
  earningToken: Token;
  stakingToken: Token;
  contractAddress: Address;
  poolCategory?: any;
  tokenPerBlock: string;
  sortOrder?: number;
  harvest?: boolean;
  isFinished?: boolean;
  enableEmergencyWithdraw?: boolean;
  distribution?: number;
  rewardAddress?: Address;
  riskPoolAddress?: Address;
  poolType?: boolean;
  ethPool?: boolean;
  lpPool?: boolean;
  poolName?: string;
  lpSymbol?: string;
  oldPool?: boolean;
  isSSRP?: boolean;
  isDisabled?: boolean;
  ethNativePool?: boolean;

  stakedTokenPriceInUsd?: number;
  rewardTokenPriceInUsd?: number;
}

export type Images = {
  lg: string;
  md: string;
  sm: string;
  ipfs?: string;
}
