import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AlertColor, Box, Card } from '@mui/material'
import AddressListResults from 'components/AddressListResults'
import AddAddress from './components/AddAddress'
import { useAuthState } from 'state/auth/hooks'
import { useGetBlackList } from 'hooks/useGetBlackList'
import CustomizedSnackbars from 'components/SnackBar'
import { addBlackList, deleteBlacklist } from 'utils/apiHelpers'
import ConfirmDlg from 'components/modals/ConfirmModal'
import { useGetChainListAll } from 'hooks/useGetChainData'
import EditBlacklistDlg from './components/EditBlacklistDlg'

interface ChainType {
  _id?: string
  chain_id?: string
  chain_name?: string
  chain_logo?: string
}

const Address = () => {
  const { userName } = useAuthState()
  const navigate = useNavigate()
  const [updated, setUpdated] = useState(false)
  const [newAddr, setNewAddr] = useState('')
  const [serverity, setServerity] = useState<AlertColor>('success')
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [message, setAlertMessage] = useState('')
  const [selectedChains, setChains] = useState<ChainType>({})

  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(0)

  const [confirmDlgOpen, setConfirmDlgOpen] = useState(false)
  const [deleteId, setDeleteId] = useState<any>({})

  const [openEditDlg, setOpenEditDlg] = useState(false)
  const [editItem, setEditItem] = useState(null)

  const { bladdresses, totalItems } = useGetBlackList(page, limit, updated)
  const { chains } = useGetChainListAll(updated)

  useEffect(() => {
    if (userName === '') {
      navigate('/')
    }
  }, [])

  const handleSetNewAddress = (e: any) => {
    setNewAddr(e.target.value)
  }

  const AddBlacklist = () => {
    if (!newAddr.includes('0x') || newAddr.length !== 42) {
      handleOpenSnackBar('warning', 'Invalid address.')
      return
    }
    if (!selectedChains?.chain_id) {
      handleOpenSnackBar('warning', 'Invalid chain id.')
      return
    }
    
    addBlackList({
      address: newAddr,
      chain_id: selectedChains.chain_id,
    })
      .then((res: any) => {
        if (res.success) {
          setNewAddr('')
          setChains({})
          handleOpenSnackBar('success', res.message)
        } else {
          setNewAddr('')
          handleOpenSnackBar('error', res.message)
        }
      })
      .catch((err: any) => {
        setNewAddr('')
        handleOpenSnackBar('error', err.message)
      })
  }

  const hanleDeleteBlacklist = (id: string, address: string) => {
    setDeleteId({id, address})
    setConfirmDlgOpen(true)
  }
  const handleDeleteConfirm = () => {
    if (deleteId === '') {
      return
    }
    deleteBlacklist(deleteId.id, deleteId.address)
      .then((res: any) => {
        if (res.success) {
          handleOpenSnackBar('success', res.message)
          setDeleteId('')
          setConfirmDlgOpen(false)
        } else {
          handleOpenSnackBar('error', res.message)
          setConfirmDlgOpen(false)
        }
      })
      .catch((err: any) => {
        handleOpenSnackBar('error', err.message)
      })
  }

  const hanleEditBlacklist = (item: any) => {
    setEditItem(item)
    setOpenEditDlg(true)
  }

  const handleOpenSnackBar = (type: AlertColor, msg: string) => {
    setServerity(type)
    setAlertMessage(msg)
    setSnackBarOpen(true)
    setUpdated(!updated)
  }

  const handleSnackBarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackBarOpen(false)
  }

  const handleSelectChains = (e: any, v: ChainType) => {
    setChains(v)
  }

  return (
    <>
      <Box
        component="main"
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          p: '20px',
        }}
      >
        {/* <MainHeader /> */}
        <Card sx={{ marginTop: 10, overflowX: 'auto', minWidth: '280px', width: '100%', maxWidth: '800px' }}>
          <AddAddress
            value={newAddr}
            chainData = {chains ?? []}
            handleAddBlacklist={AddBlacklist}
            handleSetNewAddress={handleSetNewAddress}
            handleSelectChains={handleSelectChains}
          />
          <AddressListResults
            addresses={bladdresses}
            limit={limit}
            page={page}
            totalCounts={totalItems}
            hanleEditBlacklist={hanleEditBlacklist}
            setPage={(p: number) => setPage(p)}
            setLimit={(l: number) => setLimit(l)}
            hanleDeleteBlacklist={hanleDeleteBlacklist}
          />
        </Card>
        <EditBlacklistDlg
          open={openEditDlg}
          chainData={chains ?? []}
          item={editItem}
          handleClose={() => setOpenEditDlg(false)}
          handleOpenSnackBar={handleOpenSnackBar}
        />
        <ConfirmDlg
          open={confirmDlgOpen}
          handleClose={() => setConfirmDlgOpen(false)}
          handleConfirm={handleDeleteConfirm}
        />
        <CustomizedSnackbars
          serverity={serverity}
          snackBarOpen={snackBarOpen}
          message={message}
          handleSnackBarClose={handleSnackBarClose}
        />
      </Box>
    </>
  )
}

export default Address
