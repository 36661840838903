import React from 'react'
import { Box, Button, TextField, Autocomplete } from '@mui/material'
interface ChainType {
  _id?: string
  chain_id?: string
  chain_name?: string
  chain_logo?: string
}

const ChainSelectBox: React.FC<{ data: any; handleSelectChains?: (e: any, v: any) => void }> = ({
  data,
  handleSelectChains,
}) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
      {/* <Typography gutterBottom sx={{ minWidth: 50 }}>
        Chain :
      </Typography> */}
      <Autocomplete
        limitTags={2}
        id="multiple-limit-tags"
        options={data}
        getOptionLabel={(option) => option.chain_name}
        // defaultValue={data[0]}
        renderInput={(params) => <TextField {...params} label="Supported chain" placeholder="chains" />}
        fullWidth
        sx={{ minWidth: 250 }}
        size="small"
        onChange={handleSelectChains}
      />
    </Box>
  )
}

const ProtocolSelectBox: React.FC<{ data: any; handleSelectProtocol?: (e: any, v: any) => void }> = ({
  data,
  handleSelectProtocol,
}) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
      <Autocomplete
        limitTags={2}
        id="multiple-limit-tags"
        options={data}
        getOptionLabel={(option) => option.product_name}
        // defaultValue={data[0]}
        renderInput={(params) => <TextField {...params} label="Supported protocol" placeholder="protocols" />}
        fullWidth
        sx={{ minWidth: 250 }}
        size="small"
        onChange={handleSelectProtocol}
      />
    </Box>
  )
}

const TokenSelectBox: React.FC<{ data: any; handleSelectToken?: (e: any, v: any) => void }> = ({
  data,
  handleSelectToken,
}) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
      <Autocomplete
        multiple
        limitTags={2}
        id="multiple-limit-tags"
        options={data}
        getOptionLabel={(option) => option.token_name}
        // defaultValue={[data[0]]}
        renderInput={(params) => <TextField {...params} label="Supported tokens" placeholder="Tokens" />}
        fullWidth
        sx={{ minWidth: 250 }}
        size="small"
        onChange={handleSelectToken}
      />
    </Box>
  )
}

const AddAddress: React.FC<{
  value: string
  chainData?: any
  protocols?: any
  tokens?: any
  handleAddBlacklist: () => void
  handleSetNewAddress: (e: any) => void
  handleSelectChains: (e: any, v: ChainType) => void
  handleSelectProtocol: (e: any, v: any) => void
  handleSelectToken: (e: any, v: any) => void
}> = ({
  value,
  chainData,
  protocols,
  tokens,
  handleAddBlacklist,
  handleSetNewAddress,
  handleSelectChains,
  handleSelectProtocol,
  handleSelectToken,
}) => {
  return (
    <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Box sx={{ py: 2, width: '100%' }}>
        <TextField
          fullWidth
          size="small"
          placeholder="Enter whitelisted address"
          variant="outlined"
          onChange={handleSetNewAddress}
          value={value}
        />
        <ChainSelectBox data={chainData} handleSelectChains={handleSelectChains} />
        <ProtocolSelectBox data={protocols} handleSelectProtocol={handleSelectProtocol} />
        <TokenSelectBox data={tokens} handleSelectToken={handleSelectToken} />
      </Box>
      <Box sx={{ marginLeft: 5 }}>
        <Button color="success" disabled={false} size="small" variant="contained" onClick={handleAddBlacklist}>
          Add
        </Button>
      </Box>
    </Box>
  )
}

export default AddAddress
