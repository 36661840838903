import { Box, Button } from '@mui/material'
import React from 'react'

const PageHeader: React.FC<{
  handleOpenAddChainDlg: () => void;
}> = ({ handleOpenAddChainDlg }) => {
  return (
    <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div />
      <Box sx={{ marginLeft: 5 }}>
        <Button color="success" disabled={false} size="small" variant="contained" onClick={handleOpenAddChainDlg}>
          Add New
        </Button>
      </Box>
    </Box>
  )
}

export default PageHeader
