
export enum ChainId {
  MAINNET = 1,
  TESTNET = 4,
  // KAVA = 2221,
}

export const ChainIds = {
  ETH : 1,
  // KAVA : 2221,
}

export const NetworkContextName = 'NETWORK'

export { default as poolsConfig } from './pools'
