import { useEffect, useState } from 'react'
import { getCapitalAgentContract } from 'utils/contractHelpers'
import { getBalanceAmount } from 'utils/formatBalance'

const useGetAvailableCoverAmountInfos = () => {
  const [availableInfos, setAvailableInfos] = useState(null)

  useEffect(() => {
    const fetch = async () => {
      const capitalContract = getCapitalAgentContract();
      const mlr = await capitalContract.MLR();
      const mcr = await capitalContract.MCR();
      const totalCapitalStaked = await capitalContract.totalCapitalStaked();
      const totalUtilizedAmount = await capitalContract.totalUtilizedAmount();

      const mlrDecimals = getBalanceAmount(mlr.toString(), 18).toNumber();
      const mcrDecimals = getBalanceAmount(mcr.toString(), 18).toNumber();

      const tcsDecimals = getBalanceAmount(totalCapitalStaked.toString(), 6).toNumber();
      const tuaDecimals = getBalanceAmount(totalUtilizedAmount.toString(), 6).toNumber();

      setAvailableInfos({
        mcr: mcrDecimals,
        mlr: mlrDecimals,
        totalCapitalStaked: tcsDecimals,
        totalUtilizedAmount: tuaDecimals
      })
    }
    fetch();
  }, [])
  return availableInfos
}

export default useGetAvailableCoverAmountInfos
