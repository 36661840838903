import { useQuery } from '@tanstack/react-query'
import { getProtocols, type ProtocolsResponse } from '../utils/apiHelpers'

export function useProtocols() {
  return useQuery({
    queryKey: ['protocols'],
    queryFn: async () => {
      const response = await getProtocols()

      return response.data.data.protocols.reduce((acc, protocol) => {
        acc[protocol.protocol_id.toLowerCase()] = protocol

        return acc
      }, {}) as Record<string, ProtocolsResponse['data']['protocols'][0]>
    },
  })
}
