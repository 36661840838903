import { useState, useEffect } from 'react';
import { getProtocolListsWithPagination, getProtocolListAll } from 'utils/apiHelpers';

export const useGetProtocolList = (page: number, limit: number, status: number[], search?: string, updated?: boolean) => {
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    const fetch = async () => {
      const res: any = await getProtocolListsWithPagination(page, limit, status, search);
      if (res?.success){
        setData(res?.data)
      }
    }
    fetch();  
  }, [page, limit, search, updated, status]);

  return data;
}

export const useGetProtocolListAll = () => {
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    const fetch = async () => {
      const res: any = await getProtocolListAll();
      if (res?.success){
        setData(res?.data)
      }
    }
    fetch();  
  }, []);

  return data;
}
