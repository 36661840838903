import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import Carousel from './components/Carousel'
// import MainHeader from './components/MainHeader'
import ProtocolBook from './components/ProtocolBook'
import { useAuthState } from 'state/auth/hooks'
import { useGetPoolsPublicData } from 'state/pools/hooks'

const Home = () => {
  const { userName } = useAuthState()
  const navigate = useNavigate()
  useGetPoolsPublicData()
  
  useEffect(() => {
    if (userName === '') {
      navigate('/');
    }
  }, [])

  return (
    <>
      <Box
        component="main"
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          p: '20px',
        }}
      >
        {/* <MainHeader /> */}
        <Carousel />
        <ProtocolBook />
      </Box>
    </>
  )
}

export default Home
