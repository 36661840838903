import { useEffect, useState } from 'react'
import { fetchLpAmount } from 'state/pools/fetchPools'
import { fetchUsdPrices } from 'utils/apiHelpers'
import { lpTokens } from 'config/constants/tokens'

export const useGetUnoreUsdPrice = () => {
  const [usdPrice, setUsdPrice] = useState(null)

  useEffect(() => {
    const getPrice = async () => {
      const prices = await fetchUsdPrices()
      const lpAmounts = await fetchLpAmount()
      lpTokens.forEach((element, index) => {
        prices[`${element.symbol}`] = lpAmounts[`${element.symbol}`] * prices[`${element.originTokenSymbol}`]
        if (index === lpTokens.length - 1) {
          setUsdPrice(prices)
        }
      })
    }
    getPrice()
  }, [])

  return usdPrice
}
